export default class ApisStripePortalCliente {

    static criar(dados) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("token_ambiente", process.env.REACT_APP_API_NODE_PRIVADA_TOKEN_AMBIENTE);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dados);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            fetch(`${process.env.REACT_APP_API_NODE_BASE_URL}/privada/stripe/portal/cliente/criar`, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

}
