export default class ApisStripeCliente {

    static async obter(dados) {
        return new Promise(async (resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("token_ambiente", process.env.REACT_APP_API_NODE_PRIVADA_TOKEN_AMBIENTE);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dados);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            fetch(`${process.env.REACT_APP_API_NODE_BASE_URL}/privada/stripe/cliente/obter`, requestOptions)
                .then(async (response) => {
                    let res = await response.json();
                    let inputsNull = [`'email' of undefined`, `'email' of null`];
                    if (inputsNull.find(v => String(res?.resposta).includes(v))) {
                        resposta({ resposta: { assinaturasAtivas: [{ plan: { produtoDetalhes: { metadata: { codeRef: "null-null" } } } }] } });
                    } else {
                        resposta(res);
                    }
                })
                .catch(err => {
                    error(err);
                });

        });
    }

}
