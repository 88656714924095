import $ from 'jquery';
import ApisUser from './app/apis/user';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function appPixCopiar(shelf) {
    navigator.clipboard.writeText(shelf.state.dadosUser?.paginaAppsConfig?.["5_valordachave10"]);
    alert(`Copiado com sucesso!`);
}

export function sairDaConta() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("paginaId");
    window.location.href = `${process.env.REACT_APP_API_NODE_BASE_URL}/privada/usuario/deslogar`;
}

export function verificarUserLogado(funLogado, funDeslogado) {
    return new Promise(async (resolve, reject) => {
        try {
            const respostaDadosUser = await ApisUser.pegarUserPorToken(localStorage.getItem("userToken"));
            if (respostaDadosUser.resposta) {
                funLogado();
                resolve("User logado.");
            } else {
                funDeslogado();
                reject("User deslogado.");
            }
        } catch (error) {
            funDeslogado();
            reject("User deslogado.");
        }
    })
}

export function verificarUserLogadoOffline(funLogado, funDeslogado) {
    try {
        if (localStorage.getItem("userToken")) {
            funLogado();
        } else {
            funDeslogado();
        }
    } catch (error) {
        funDeslogado();
    }
}

export function imgErrorMudar(element, urlReplace) {
    $(document).ready(function () {
        $(element).error(function () {
            $(this).attr("src", urlReplace);
        });
    });
}

export function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}

export function AtivarSelect2(select, placeholder) {
    $(document).ready(function () {
        $(select).select2({
            placeholder,
            selected: true,
            width: "100%"
        });
    });
}

export function GetValSelect2(element) {
    return new Promise((resolve) => {
        $(document).ready(function () {
            resolve($(element).select2().select2('val'));
        });
    });
}

export function SetValSelect2(element, v) {
    $(document).ready(function () {
        $(element).select2().select2('val', v)
    });
}

export function titleize(text) {
    var words = text.toLowerCase().split(" ");
    for (var a = 0; a < words.length; a++) {
        var w = words[a];
        words[a] = w[0].toUpperCase() + w.slice(1);
    }
    return words.join(" ");
}