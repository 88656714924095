import React from 'react';
import LayoutError from '../../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../../layout/LayoutCarregando';
import { Link } from 'react-router';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="PaginaUsuarioPainelLojaPagamentoSucesso container">
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "70vh" }}>
          {
            shelf?.state?.sessaoCheckout?.payment_status === "paid" ? (<>
              <img src="https://i.imgur.com/t9IoNRd.png" alt="Sucesso" width="300rem" />
              <h1 className="text-center">Obrigado pela sua compra!</h1>
              <p className="text-center">O seu pagamento já foi confirmado do produto "{shelf?.state?.sessaoCheckout?.lineItems?.data?.[0]?.description}" ele será ativado em alguns minutos automaticamente na sua conta do iCartão e já poderá utilizar.</p>
            </>) : (<>
              <img src="https://i.imgur.com/EWUYnox.png" alt="Aguarde" width="300rem" />
              <h1 className="text-center">Pagamento em analise, Obrigado pela sua compra!</h1>
              <p className="text-center">O seu pagamento está em análise em alguns minutos deve ser aprovado e o produto "{shelf?.state?.sessaoCheckout?.lineItems?.data?.[0]?.description}" deve ser ativado em alguns minutos automaticamente na sua conta do iCartão e já poderá utilizar.</p>
            </>)
          }
          <p>Caso tenha algum problema aciona nossa equipe de atendimento por e-mail contato@icartao.me</p>

          
          <Link to="/usuario/painel/loja" className="btn btn-painel-roxo">Retornar a loja</Link>

        </div>
      </div >
    );
  }
}
