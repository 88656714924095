import React from 'react';
import LayoutError from '../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../layout/LayoutCarregando';
import Editor from "@monaco-editor/react";
import ReactJson from 'react-json-view';
import PaginaUsuarioCartao from '../../../../usuario/cartao/cartao';
import $ from 'jquery';
import ComponenteCardTema from '../../../../../componentes/cardTema/cardTema';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaDevTemas">
        <div className="container">
          <br /><br />


          <div className="row no-gutters">
            <div className="col-sm-8 coluna-form">
              <form className="dados-form" id="formDadosEditarTema" onSubmit={shelf.formEditarTemas} >



                <label htmlFor="status"><p>Status:</p></label>
                <select name="status" id="status" className="form-control form-card" defaultValue={shelf.state?.tema?.status} required="required">
                  <option value="rascunho">Rascunho</option>
                  <option value="publicado">Publicado</option>
                  <option value="recusado">Recusado</option>
                </select>

                <label htmlFor="codigoCss" className='mt-5'><p>Código CSS:</p></label>
                <Editor
                  name="codigoCss"
                  id="codigoCss"
                  defaultValue={shelf.state?.codigoCss}
                  onChange={(value) => { shelf.setState({ codigoCss: value }) }}
                  height="30vh"
                  width="100%"
                  defaultLanguage="css"
                  options={{
                    minimap: {
                      enabled: false
                    }
                  }}
                />
                <small className="text-info">Esse código é onde você deve mais focar pois é ele que vai deixar o seu tema único, para saber as classes CSS você deve utilizar o inspencionar do Google Chorme.</small>
                <br /><br />


                <label htmlFor="predefinicoes" className='mt-5'><p>Código de pre-definições:</p></label>
                <Editor
                  name="predefinicoes"
                  id="predefinicoes"
                  defaultValue={shelf.state?.predefinicoes}
                  onChange={(value) => { shelf.setState({ predefinicoes: value }) }}
                  height="30vh"
                  width="100%"
                  defaultLanguage="json"
                  options={{
                    minimap: {
                      enabled: false
                    }
                  }}
                />
                <small className="text-info">Pre-definições é um codigo JSON que você pode fazer para quer o seu tema acione configurações da conta do usuario que instalar ele e você poderá mudar algumas coisas como links da pagina do usuario, tema base, cor base, etc... Consulte a documentação a baixo para ver o nome dos campos e o que eles fazem.</small>
                <br /><br />

                <div className="DocumentacaoPreDefinicoes mt-5">
                  <h2>Documentação:</h2>

                  <ReactJson name={false} displayDataTypes={false} src={
                    {
                      cor: "String da cor base, opções disponiveis ['padrao', 'verde', 'azul', 'vermelho', 'amarelo', 'rosa', 'preto', 'branco', 'preto-branco']",
                      modelo: "String do tema base, opções disponiveis ['BARMENUS', 'QUADRADOS', 'PADRAO']",
                      linksPadrao: [{
                        posicao: "Número de 0 a 999, onde 0 é o topo e 999 é o fundo.",
                        titulo: "String do titulo do link.",
                        link: "String onde o botão do link irá dicionar na web. Ex: 'https://www.google.com.br'",
                        icone: "String com a class fontAwesome do icone do botão. Ex: 'fas fa-home', consulte a lista aqui: https://bit.ly/3L2Z7Cl",
                      }]
                    }
                  } />
                </div>
                <br /><br />


                <label htmlFor="nome"><p>Nome:</p></label>
                <input defaultValue={shelf.state?.tema?.nome} type="text" className="form-control form-card" name="nome" id="nome" placeholder="Nome " required />
                <br />


                <label htmlFor="PrintFile"><p>Print:</p></label>
                <input type="file" accept="image/gif, image/png" max="1" className="form-control form-card" name="PrintFile" id="PrintFile" />
                <small className="text-info">Só escolha um arquivo nesse campo caso queira alterar a print.</small>
                <br /><br />

                <label htmlFor="descricao"><p>Descrição:</p></label>
                <textarea className="form-control form-card" name="descricao" id="descricao" defaultValue={shelf.state?.tema?.descricao} placeholder="Descrição breve do seu aplicativo."></textarea>
                <br />

                <label htmlFor="preco"><p>Preço:</p></label>
                <input type="number" min="0" max="10" className="form-control form-card" name="preco" id="preco" defaultValue={shelf.state?.tema?.preco} placeholder="Preço " required />
                <br />



                <br />
                <br />

                <center><button type="submit" id="btnSubmitForm" className="btn btn-painel-roxo btn-lg"><i class="fad fa-save"></i> Salvar</button></center>


              </form>

              <div className="exemploExibicao mt-5">
                <h2>Exemplo de exibição do temas na loja:</h2>

                <div className="col-6 col-md-4">
                  <ComponenteCardTema
                    tema={shelf.state?.tema}
                    disabled={true}
                  />
                </div>

              </div>

            </div>


            <div className="col-sm-4 remove-mobile" scrolling="no">
              {
                ((shelf?.state?.codigoCss) || (shelf?.state?.predefinicoes)) && (
                  <div className="imagem-preview preview-pagina">
                    <PaginaUsuarioCartao
                      modoDev={{
                        state: shelf?.state
                      }}
                      params={{
                        slug: "meu-icartao"
                      }}
                    />
                  </div>
                )
              }
              <center><button onClick={() => { $(`#btnSubmitForm`).click(); }} className="btn btn-painel-roxo btn-lg mt-3"><i class="fad fa-save"></i> Salvar</button></center>
            </div>

          </div>




          <br />
          <br />

        </div >
      </div >
    );
  }
}
