import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import $ from 'jquery';
import PaginaUsuarioCartao from '../../cartao/cartao';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaUser">
        <div className="container">

          {
            shelf.state.pMsg &&
            <div>
              <br /><br />
              <div className="alert alert-info" role="alert">
                <span>{shelf.state.pMsg}</span>
              </div>
            </div>
          }

          <br />

          <div className="row no-gutters">
            <div className="col-sm-8 coluna-form">
              <form className="dados-form" id="formDadosEditarUser" onSubmit={shelf.pegarDadosFormAtualizarPagina}>

                <div className="card card-link cardWhatsapp">
                  <div className="card-body row">

                    <div className="col-3">
                      {/* eslint-disable-next-line */}
                      <a className="icone">
                        <i className="fab fa-whatsapp fa-3x icone-whatsapp"></i>
                      </a>
                    </div>

                    <div className="col-7">
                      <input defaultValue="Whatsapp" type="text" className="form-control form-card" placeholder="Título" disabled />
                      {
                        shelf.state.ocultarWhats &&
                        <input defaultValue={shelf.state.dadosPagina.link_whatsapp} type="text" className="form-control form-card" name="link_whatsapp" id="link_whatsapp" placeholder="Insira o número:" />
                      }

                    </div>

                    <div className="col-1 icon-eye">
                      {/* eslint-disable-next-line */}
                      <a className="icone" onClick={() => { shelf.ocultarWhats(shelf, shelf.state.ocultarWhats); }}>
                        {
                          shelf.state.ocultarWhats === false ?
                            <i className="fas fa-eye-slash"></i>
                            :
                            <i className="fas fa-eye"></i>
                        }
                      </a>
                    </div>

                  </div>
                </div>

                <div className="cards-links-move">

                  {
                    shelf.state.dadosPagina.links &&
                    shelf.state.dadosPagina.links.map(link => (

                      <div className={"card-link-move card-link-" + link.posicao} id="card-link-move" posicao={link.posicao} pAntiga={link.posicao}>
                        <div className="card card-link">
                          <div className="card-body row">
                            <div className="col-2 icone-mover-card">
                              <input defaultValue={link.posicao + ""} type="hidden" className="form-control form-card" name={"posicao-" + link.posicao} />
                              <span className="posicao">{link.posicao}</span>
                              <i className="fal fa-arrows-v fa-2x"></i>
                            </div>

                            <div className="col-2">
                              <span remove-mobile="true">Ícone:</span><br />
                              <input defaultValue={link.icone + ""} type="hidden" className="form-control form-card" name={"icone-" + link.posicao} />
                              {/* eslint-disable-next-line */}
                              <a onClick={() => { $("#galeria-icones").attr("link-posicao-ativo", link.posicao) }} data-bs-toggle="modal" data-bs-target="#galeria-icones" className="icone" title="Escolha seu ícone">
                                <i id={"iconeView-" + link.posicao} className={link.icone + " fa-2x icone-perfil"}></i>
                              </a>
                            </div>

                            <div className="col-6">
                              <input defaultValue={link.titulo + ""} type="text" className="form-control form-card" name={"titulo-" + link.posicao} placeholder="Título" />
                              <input defaultValue={link.link + ""} type="text" className="form-control form-card" name={"link-" + link.posicao} placeholder="Link (URL)" />
                            </div>

                            <div className="col-1 icon-trash">
                              {/* eslint-disable-next-line */}
                              <a onClick={() => { shelf.removeLink(shelf, link.posicao) }} className="icone">
                                <i className="fal fa-trash-alt fa-1x"></i>
                              </a>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))
                  }


                </div>

                {/* eslint-disable-next-line */}
                <center><a onClick={() => { shelf.addLink(shelf) }} className="btn btn-outline-dark btn-block" id="btnPlusTour"><i className="fad fa-plus"></i></a></center>

                <br />
                <br />

                <center><button type="submit" className="btn btn-painel-roxo btn-lg"><i class="fad fa-save"></i> Salvar Modificações</button></center>


                <br />
                <br />
              </form>

            </div>

            <div className="col-sm-4 remove-mobile" scrolling="no">
              <button className="btn-link-copy" onClick={() => navigator.clipboard.writeText(`https://icartao.me/@${shelf.state.dadosPagina.slug}`)}>
                <i className="far fa-clone"></i>
              </button>
              {/* eslint-disable-next-line */}
              <a className="a-link" target="_blank" href={process.env.PUBLIC_URL + "/p/" + shelf.state.dadosPagina.slug}>icartao.me{"/@" + shelf.state.dadosPagina.slug}</a>
              <br /><br />

              <div className="imagem-preview preview-pagina">
                <PaginaUsuarioCartao
                  modoDev={{
                    state: {
                      predefinicoes: JSON.stringify(shelf?.state?.dadosPagina) || {}
                    }
                  }}
                  params={{
                    slug: "meu-icartao"
                  }}
                />
              </div>
              <br />
            </div>

          </div>
          <br />
        </div >



        <div className="modal fade modal-galeria-icones" id="galeria-icones" tabindex="-1" aria-labelledby="modalabel" aria-hidden="true" link-posicao-ativo="VAZIO">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalabel">Galeria de icone<br />
                  <p>Selecione um ícone:</p></h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="icones">
                  <div className="favoritos">
                    {
                      shelf.state.listaIconesFavoritos.icons.map(icone => (<>
                        {/* eslint-disable-next-line */}
                        <a onClick={() => { shelf.modalBtnSelecionaIcone(icone) }} className="btn-icone">
                          <i className={icone + " fa-3x"}></i>
                        </a>
                      </>))
                    }
                  </div>
                </div>
                <center>
                  <button id="botaoModalVerTodos" onClick={shelf.modalBtnVerTodos} tipo="todos" className="btn btn-painel-roxo btn-lg">Ver todos os icones</button>
                </center>
                <div className="icones">
                  <div className="outros">
                    <center className="container display-block">
                      <br />
                      <input className="form-control display-block" name="buscar-icone" type="text" placeholder="Buscar ícone" autoComplete="off" onChange={() => shelf.buscarIcones(shelf)}></input>
                      <br />
                    </center>
                    {
                      shelf.state.listaIcones.icons &&
                      shelf.state.listaIcones.icons.map(icone => (<>
                        {/* eslint-disable-next-line */}
                        <a onClick={() => { shelf.modalBtnSelecionaIcone(icone) }} className="btn-icone">
                          <i className={icone + " fa-3x"}></i>
                        </a>
                      </>))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div >





    );
  }
}
