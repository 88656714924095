import { Component } from 'react';
import html from './editar.jsx';
import ApiUpload from '../../../../../apis/upload.js';
import ApisTemas from '../../../../../apis/temas.js';
import ApisStripeProduto from '../../../../../apis/stripeProduto.js';
import config from '../../../../../../config.js';
import stringToBase64 from '../../../../../functions/stringToBase64.js';
import pegarInfosUser from '../../../../../functions/pegarInfosUser.js';


export default class PaginaDevPainelTemasEditar extends Component {
  constructor(props) {
    super(props);
    this.formEditarTemas = this.formEditarTemas.bind(this, this);
    this.state = {
      erro: null,
      carregando: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this, true, "dev");
    try {
      if (this.props.params.slug) {
        let tema = await ApisTemas.getSlug(this.props.params.slug);
        tema = tema.resposta[0];
        await this.setState({
          carregando: false,
          tema,
          codigoCss: tema?.codigoCss,
          predefinicoes: tema?.predefinicoes
        });
      } else {
        await this.setState({
          carregando: false
        });
      }
    } catch (error) {
      await this.setState({
        carregando: false,
        erro: error
      });
    }
  }


  async formEditarTemas(shelf, shelfHtml) {
    try {
      shelfHtml.preventDefault();
    } catch (error) { }
    let dadosFormFormado = {};
    let pegarDadoForm = new FormData(shelfHtml.target);
    pegarDadoForm.forEach((valor, chave) => {
      dadosFormFormado[chave] = valor;
    });


    dadosFormFormado.predefinicoes = this.state?.predefinicoes;
    dadosFormFormado.codigoCss = await stringToBase64(this.state?.codigoCss);


    // Pegar arquivo e preparar para upload
    let pegarArquivo = dadosFormFormado.PrintFile;
    var formdataPrint = new FormData();
    formdataPrint.append("arquivo", pegarArquivo);
    delete dadosFormFormado.PrintFile;



    await shelf.setState({
      carregando: true
    })

    try {
      if (pegarArquivo?.name) {
        const respostaUpload = await ApiUpload.prototype.uploadArquivo(formdataPrint);
        if (respostaUpload.status === "OK") {
          dadosFormFormado["prints"] = [respostaUpload.resposta.nome];
        } else {
          await shelf.setState({
            carregando: false,
            erro: respostaUpload.statusMotivo
          });
          return;
        }
      }

      const respostaPost = await ApisTemas.put(dadosFormFormado, shelf.state?.tema?.id);
      if (respostaPost.status === "OK") {
        
        let stripeDados = {
          query: {
            codeRef: `tema-${shelf.state?.tema?.id}`,
          },
          data: {
            nome: `Tema ${dadosFormFormado.nome} | Plano Anual`,
            preco: Number(`${dadosFormFormado.preco}00`),
            descricao: dadosFormFormado.descricao,
          }
        };
        if(shelf.state?.tema?.prints?.[0]){
          console.log(shelf.state?.tema?.prints?.[0]);
          stripeDados.data.imagens = [`${config.arquivos.baseUrl}${shelf.state?.tema?.prints?.[0]}`]
        }
        if (dadosFormFormado.icone) {
          stripeDados.data.imagens = [`${config.arquivos.baseUrl}${dadosFormFormado.icone}`]
        }
        await ApisStripeProduto.editar(stripeDados);
        window.location.reload();

      } else {
        await shelf.setState({
          carregando: false,
          erro: respostaPost.statusMotivo
        })
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      })
    }

  }

  render() {
    return html(this);
  }
}