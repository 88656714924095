export default class ApiJsonListaIcons {

  pegarListaIconsFavoritos() {
    return new Promise((resposta, error) => {

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(process.env.PUBLIC_URL +"/iconesPadrao.json", requestOptions)
        .then(response => resposta(response.json()))
        .catch(err => error(err));

    });
  }

  pegarListaIcons() {
    return new Promise((resposta, error) => {

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(process.env.PUBLIC_URL +"/fw/fontawesome-pro-5.12.0-web/list-names-icons-font-awesome-v5.0.1.json", requestOptions)
        .then(response => resposta(response.json()))
        .catch(err => error(err));

    });
  }

}