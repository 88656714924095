import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import config from '../../../../../config';
import { Link } from '@version/react-router-v3';
import selecionarPagina from '../../../../functions/selecionarPagina';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaUser container mt-4">
        <div className="row justify-content-md-center">
          <div className="col col-lg-5">

            {shelf.state.dadosUser.paginas.map((pagina) => (
              <div key={pagina.id} className="w-100" style={{ cursor: "pointer" }} onClick={() => { selecionarPagina(pagina.id) }}>
                <div className="card mb-3 p-3 d-flex align-items-center flex-row">
                  <div className="col-auto imagem-perfil">
                    {/* eslint-disable-next-line */}
                    <img
                      src={config.arquivos.baseUrl + pagina.foto}
                      className="img-fluid rounded-circle"
                      alt="Card image"
                    />
                  </div>
                  <div className="col">
                    <div className="card-body">
                      <h5 className="card-title text-right">{pagina.nome} <i className="ms-4 fas fa-chevron-right"></i></h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}


            <Link to="/usuario/painel/paginas/criar">
              <div className="w-100" style={{ cursor: "pointer" }}>
                <div className="card mb-3 p-3 d-flex align-items-center flex-row">
                  <div className="col-auto">
                    <i class="fas fa-plus-circle fa-5x"></i>
                  </div>
                  <div className="col">
                    <div className="card-body">
                      <h5 className="card-title text-right">Criar nova página<i className="ms-4 fas fa-chevron-right"></i></h5>
                    </div>
                  </div>
                </div>
              </div>
            </Link>

          </div>
        </div>
      </div>
    );
  }
}
