import config from "../../config";

export default async function paginaLinkShare(shelf) {
  if (window.navigator.share !== undefined) {
    window.navigator.share({
      title: shelf.state.dadosPagina.nome + " - " + config.site.titulo,
      text: 'Esse é meu iCartão com todos meus contatos:',
      url: `https://icartao.me/@${shelf.state.dadosPagina.slug}`,
    })
      .then(() => {
        console.log('[navigator.share] Successful share')
        // alert("iCartão compartilhado 😄");
      })
      .catch((error) => {
        console.log('[navigator.share] Error sharing', error)
        alert("Ocorreu um erro ao compartilhar 🙁‍, use a opção de copiar o link!");
      });
  } else {
    console.log("[navigator.share] Não disponivel");
    alert("Não é suportado 🙅‍, use a opção de copiar o link!");
  }
}