import React, { Component } from 'react';
import { Link } from 'react-router';

export default class LayoutNavegacaoCelular extends Component {


    render() {
        let paginas = [
            {
                nome: "Início",
                link: "/usuario/painel/inicio",
                icon: "fad fa-home",
                userLogado: true
            },
            {
                nome: "Links",
                link: "/usuario/painel/links",
                icon: "fad fa-link",
                userLogado: true
            },
            {
                nome: "Personalizar",
                link: "/usuario/painel/personalizar",
                icon: "fad fa-paint-brush-alt",
                userLogado: true
            },
            {
                nome: "Loja",
                link: "/usuario/painel/loja",
                icon: "fad fa-th-large",
                userLogado: true
            },
            {
                nome: "Tutoriais",
                link: "https://www.youtube.com/@icartao",
                icon: "fab fa-youtube",
                userLogado: true,
                target: "_blank"
            }
        ]

        return (<>
            <nav className="navbar fixed-bottom navbar-expand-lg navbar-light bg-light remove-pc">
                <div className="container d-flex justify-content-center flex-row flex-nowrap">
                    {
                        paginas.map((pagina, index) => (<>
                            {
                                this.props.userLogado === pagina.userLogado && (<>
                                    {
                                        (String(pagina?.link).includes("//")) ? (
                                            <a key={`mobile${index}`} href={pagina.link} target={pagina.target || "_self"} className="navMobileLink" aria-current="page"><i className={pagina.icon}></i> <br /> {pagina.nome}</a>
                                        ) : (<>
                                            <Link key={`mobile${index}`} to={pagina.link} className="navMobileLink" aria-current="page"><i className={pagina.icon}></i> <br /> {pagina.nome}</Link>
                                        </>)
                                    }
                                </>)
                            }
                        </>))
                    }
                </div>
            </nav>
        </>
        )
    }
}

