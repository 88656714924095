import React, { Component } from 'react';
import MetaTagsPadrao from './metaTagsPadrao';

export default class LayoutModeloVazio extends Component {


    render() {
        let props = this.props;
        let nomePagina;
        try {
            nomePagina = props.routes[1].nomePagina;
        } catch (error) {
            try {
                nomePagina = props.routes[0].nomePagina;
            } catch (error) {
                nomePagina = "Pagina sem nome";
            }
        }

        return (
            <div className="LayoutModeloVazio">
                <MetaTagsPadrao nomePagina={nomePagina} />
                <div className="conteudoPagina">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

