import { Component } from 'react';
import html from './barMenus.jsx';

export default class TemaBarMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(async ()=>{
      await this.setState({
        carregando: false,
        dadosUser: this.props.dadosUser,
        dadosPagina: this.props.dadosPagina
      });
    }, 1000)
  }
  

  render() {
    return html(this);
  }
}