import { Component } from 'react';
import html from './links.jsx';
import Inputmask from "inputmask";
import $ from 'jquery';
import Draggabilly from 'draggabilly';
import Packery from 'packery';
import ApiJsonListaIcons from '../../../../apis/jsonListaIcons.js';
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';
import pegarInfosPagina from '../../../../functions/pegarInfosPagina.js';
import paginaEditar from '../../../../functions/paginaEditar.js';
import pegarDadosFormAtualizarPagina from '../../../../functions/pegarDadosFormAtualizarPagina.js';


export default class PaginaUsuarioPainelLinks extends Component {
  constructor(props) {
    super(props);
    this.pegarDadosFormAtualizarPagina = pegarDadosFormAtualizarPagina.bind(this, this);
    this.state = {
      erro: null,
      carregando: true,
      ocultarWhats: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let listaIcones = await ApiJsonListaIcons.prototype.pegarListaIcons()
    let listaIconesFavoritos = await ApiJsonListaIcons.prototype.pegarListaIconsFavoritos()
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    await this.ocultarWhats(this, this.state.dadosPagina.link_whatsapp, "WHATS_OCULTO");
    await this.setState({
      carregando: false,
      listaIcones,
      listaIconesSemFiltro: listaIcones,
      listaIconesFavoritos,
      pMsg: this.props.location.query.msg
    });
    $(".card-" + this.state.dadosPagina.modelo).addClass("selecionado");
    this.cardsLinksSortable(this);
    setInterval(() => {
      this.mudarPosicaoCardLink();
    }, 1000);
    setTimeout(() => {
      $("input[autoComplete='off']").val("");
    }, 1500);
    const shelf = this;
    if(!this.state?.dadosPagina?.links?.[0]){
      this.addLink(this);
    }else{
      $(document).ready(async function () {
          $("body").append(`<script>Onboarding.init({ servicoChave: "dc011fc6d93818021cd5c00b34c669af", guiaCodigo: "pagina_links", assinanteCodigo: "${shelf?.state?.dadosPagina?.id}", assinanteInfo: "@${shelf?.state?.dadosPagina?.slug} (${shelf?.state?.dadosUser?.nome})", checkMensagens: false, dominio: "icartao.me" });</script>`);
      });
    }
  }

  async ocultarWhats(shelf, variavel, condicao = true) {
    if (variavel === condicao) {
      await shelf.setState({
        "ocultarWhats": false
      });
    } else {
      await shelf.setState({
        "ocultarWhats": true
      });
      Inputmask({
        mask: [
          "(99) 9999-9999",
          "(99) 9 9999-9999"
        ]
      }).mask("#link_whatsapp");
    }
  }

  async removeLink(shelf, pAntiga) {
    let posicao = $(".card-link-" + pAntiga).attr("posicao");
    $("[pAntiga='" + pAntiga + "']").remove();
    delete shelf.state.dadosPagina.links[posicao - 1];
    shelf.state.dadosPagina.links = shelf.state.dadosPagina.links.sort(function (a, b) {
      return a.posicao < b.posicao ? -1 : a.posicao > b.posicao ? 1 : 0;
    });
    await paginaEditar(shelf, shelf.state.dadosPagina, "SEM_MODAL", true);
  }

  async addLink(shelf) {
    let dadosForm = $("#formDadosEditarUser").serializeArray().reduce(function (obj, item) {
      obj[item.name] = item.value;
      return obj;
    }, {});
    let cardLinkMove = $(".card-link-move");
    let links = [];
    cardLinkMove.each(function (i) {
      let p = $(this).attr("pAntiga");
      links.push({
        posicao: parseInt($("[name='posicao-" + p + "']").val()),
        titulo: dadosForm["titulo-" + p],
        link: dadosForm["link-" + p],
        icone: dadosForm["icone-" + p]
      });
    });

    let novoLink;
    try {
      novoLink = {
        posicao: (links[links.length - 1].posicao + 1),
        titulo: "",
        link: "",
        icone: "fal fa-plus"
      }
    } catch (error) {
      links = [];
      novoLink = {
        posicao: 1,
        titulo: "",
        link: "",
        icone: "fal fa-plus"
      }
    }
    links.push(novoLink);
    dadosForm.links = links;
    await paginaEditar(shelf, dadosForm, "SEM_MODAL", true);
  }

  buscarIcones(shelf) {
    var digitado = $("input[name='buscar-icone']").val();
    let listaFiltrada = shelf.state.listaIconesSemFiltro.icons.filter(function (icone) {
      return icone.toLowerCase().includes(digitado.toLowerCase());
    });
    listaFiltrada.icons = listaFiltrada;
    shelf.setState({
      listaIcones: listaFiltrada
    });
  }


  mudarPosicaoCardLink() {
    return new Promise((resolve) => {
      $(document).ready(function () {
        $(".card-link-move").each(function (i) {
          i = i + 1;
          let pAntiga = $(this).attr("pAntiga");
          $("input[name='posicao-" + pAntiga + "']").val(i);
          $(this).attr("posicao", i);
          $(this).find(".posicao").html(i + " ");
          setTimeout(() => {
            resolve("ok");
          }, 300)
        });
      });
    })
  }

  cardsLinksSortable(shelf) {
    var slidesElem = document.querySelector('.cards-links-move');
    shelf.setState({
      pckry: new Packery(slidesElem)
    });

    // get item elements
    var itemElems = shelf.state.pckry.getItemElements();
    // for each item...
    for (var i = 0, len = itemElems.length; i < len; i++) {
      var elem = itemElems[i];
      // make element draggable with Draggabilly
      shelf.setState({
        draggie: new Draggabilly(elem, {
          handle: '.icone-mover-card',
          axis: 'y'
        })
      });
      // bind Draggabilly events to Packery
      shelf.state.pckry.bindDraggabillyEvents(shelf.state.draggie);
    }

    shelf.state.pckry.on('dragItemPositioned', function (draggedItem) {
      var index = shelf.state.pckry.items.indexOf(draggedItem);
      var nextItem = shelf.state.pckry.items[index + 1];
      if (nextItem) {
        slidesElem.insertBefore(draggedItem.element, nextItem.element);
      } else {
        slidesElem.appendChild(draggedItem.element);
      }
      setTimeout(function () {
        shelf.mudarPosicaoCardLink();
      }, 300);
    });

  }

  modalBtnVerTodos() {
    var btnTipo = $("#botaoModalVerTodos").attr("tipo");
    console.log(btnTipo);
    if (btnTipo === "todos") {
      $("#galeria-icones .icones .outros").css({
        "display": "flex"
      });
      $("#botaoModalVerTodos").html("Ver menos");
      $("#botaoModalVerTodos").attr("tipo", "menos");
    } else {
      $("#galeria-icones .icones .outros").css({
        "display": "none"
      });
      $("#botaoModalVerTodos").html("Ver todos");
      $("#botaoModalVerTodos").attr("tipo", "todos");
    }
  }

  modalBtnSelecionaIcone(icone) {
    let posicao = $("#galeria-icones").attr("link-posicao-ativo");
    $("input[name='icone-" + posicao + "']").val(icone);
    $("#iconeView-" + posicao).attr("class", icone + " fa-3x icone-perfil");
    $("#galeria-icones .btn-close").click();
  }

  render() {
    return html(this);
  }
}