import ApisPaginas from "../apis/paginas";
import selecionarPagina from "./selecionarPagina";

export default async function pegarDadosFormCriarPagina(shelf, shelfHtml) {
  try {
    shelfHtml.preventDefault();
  } catch (error) { }
  let dadosFormFormado = {};
  let pegarDadoForm = new FormData(shelfHtml.target);
  pegarDadoForm.forEach((valor, chave) => {
    dadosFormFormado[chave] = valor;
  });
  
  dadosFormFormado.userId = shelf.state.dadosUser.id;

  try {
    let paginaNova = await ApisPaginas.criar(dadosFormFormado);
    selecionarPagina(paginaNova.resposta.id, "/usuario/painel/personalizar")
  } catch (error) {
    window.location.href = `/usuario/painel/paginas/criar?msg=${String(error)}`;
  }
}