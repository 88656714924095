 const config = {
    site:{
        baseUrl: "https://app.icartao.me/",
        titulo: "iCartão.me",
        tituloDesc: "Cartão De Visita Digital, Gerador de cartão de visitas.",
        descricao: "Um gerador de cartão de visitas dinâmico e incrível, gere já o seu você mesmo com um gasto de apenas 5 minutos e nada mais.",
        palavrasChaves: "cartão de visitas digital, cartão de visita dinâmico, gerador de cartão de visitas",
        themeColorPadrao: "#e9c9fa"
    },
    arquivos:{
        baseUrl: "https://arquivos.icartao.me/" 
    }
}

export default config;