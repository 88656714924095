import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import config from '../../../../../config';
import ComponenteCardTema from '../../../../componentes/cardTema/cardTema';
import ComponenteCardApp from '../../../../componentes/cardApp/cardApp';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaLoja">

        <ul className="nav nav-tabs nav-loja" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="apps-tab" data-bs-toggle="tab" data-bs-target="#apps" type="button" role="tab" aria-controls="apps" aria-selected="true">Apps</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="temas-tab" data-bs-toggle="tab" data-bs-target="#temas" type="button" role="tab" aria-controls="temas" aria-selected="false">Temas</button>
          </li>
          {/* <li className="nav-item" role="presentation">
            <button className="nav-link" id="appsInstalados-tab" data-bs-toggle="tab" data-bs-target="#appsInstalados" type="button" role="tab" aria-controls="appsInstalados" aria-selected="true">Apps Instalados</button>
          </li> */}
          <li className="nav-item">
            <button onClick={() => { shelf.onClickMinhasCompras(shelf) }} className="nav-link" id="minhasCompras-tab">Minhas Compras</button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">



          <div className="tab-pane fade" id="apps" role="tabpanel" aria-labelledby="apps-tab">
            <div className="container mt-3">

              {
                String(shelf.state?.apps) !== "undefined" ? (<>
                  {shelf.state.apps.map((app) => (<>
                    {app?.status === "publicado" && (<>
                      <ComponenteCardApp
                        app={app}
                        dadosUser={shelf.state.dadosUser}
                        dadosPagina={shelf.state.dadosPagina}
                        dadosClienteStripe={shelf.state?.dadosClienteStripe}
                        key={app.id}
                      />
                    </>)}
                  </>))}
                </>) : (<>
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <div>
                      <h1 className="text-center">Sem aplicativos</h1>
                      <p className="text-center">Ainda não temos aplicativos publicados.</p>
                    </div>
                  </div>
                </>)
              }




            </div>
          </div>



          <div className="tab-pane fade" id="temas" role="tabpanel" aria-labelledby="temas-tab">
            <div className="container mt-3">
              <div className="row">

                {
                  String(shelf.state?.temas) !== "undefined" ? (<>
                    {shelf.state.temas.map((tema) => (<>
                      {tema?.status === "publicado" && (<>
                        <div className="col-6 col-md-3">
                          <ComponenteCardTema
                            tema={tema}
                            dadosPagina={shelf.state.dadosPagina}
                            dadosClienteStripe={shelf.state?.dadosClienteStripe}
                            key={tema.id}
                          />
                        </div>
                      </>)}
                    </>))}
                  </>) : (<>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                      <div>
                        <h1 className="text-center">Sem temas</h1>
                        <p className="text-center">Ainda não temos temas publicados.</p>
                      </div>
                    </div>
                  </>)
                }

              </div>
            </div>
          </div>


          <div className="tab-pane fade" id="appsInstalados" role="tabpanel" aria-labelledby="appsInstalados-tab">
            <div className="container mt-3">

              {
                shelf.state?.appsInstalados !== [] ? (<>
                  {shelf.state.appsInstalados.map((app) => (<>
                    {app?.status === "publicado" && (<>
                      <div className="card card-loja mb-3 p-1">
                        <div className="row g-0">
                          <div className="col-2 col-md-1 d-grid align-content-center">
                            <img src={config.arquivos.baseUrl + app?.icone} className="img-fluid rounded-start" alt="Icone do app." />
                          </div>
                          <div className="col-7 col-md-10 ps-2">
                            <h5 className="card-title">{app?.nome}</h5>
                            <p className="card-text limite-1linha-ch-100p"><small className="text-muted">{app?.descricao}</small></p>
                          </div>
                          <div className="col-3 col-md-1 d-flex align-content-center justify-content-center">
                            {
                              app?.instalado && (<>
                                <button className="btn btn-default text-center">
                                  Configurar
                                </button>
                              </>)
                            }
                          </div>
                        </div>
                      </div>
                    </>)}
                  </>))}
                </>) : (<>
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <div>
                      <h1 className="text-center">Sem aplicativos</h1>
                      <p className="text-center">Você ainda não instalou nenhum app na sua conta.</p>
                    </div>
                  </div>
                </>)
              }



            </div>
          </div>


        </div>
      </div >
    );
  }
}
