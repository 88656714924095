import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import { Link } from '@version/react-router-v3';
import imprimirQrCode from '../../../../functions/imprimirQrCode';
import paginaLinkShare from '../../../../functions/paginaLinkShare';
import PaginaUsuarioCartao from '../../cartao/cartao';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaUser">
        <div className="container">

          {
            shelf.state.pMsg &&
            <div>
              <br /><br />
              <div className="alert alert-info" role="alert">
                <span>{shelf.state.pMsg}</span>
              </div>
            </div>
          }
          <br />


          <div className="row no-gutters">
            <div className="col-sm-8 coluna-form">

              <Link class="text-decoration-none" to="/usuario/painel/paginas">
                <h3 className="text-center text-dark">{shelf.state.dadosPagina.nome} <i className="ms-2 fas fa-chevron-down"></i></h3>
              </Link>
              <br />
              <center>
                <div className="col-sm-4" scrolling="no">
                  <div className="remove-pc">
                    <button className="btn-link-copy" onClick={() => navigator.clipboard.writeText(`https://icartao.me/@${shelf.state.dadosPagina.slug}`)}>
                      <i className="far fa-clone"></i>
                    </button>
                    <a className="a-link" target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "/p/" + shelf.state.dadosPagina.slug}>icartao.me{"/@" + shelf.state.dadosPagina.slug}</a>
                    <button className="btn-link-share" onClick={() => { paginaLinkShare(shelf) }}>
                      <i className="far fa-share"></i>
                    </button>
                    <br />
                    <Link to={"/usuario/painel/ver-meu-icartao"} className="btn btn-link-copy align-middle">
                      <i className="far fa-eye"></i> Ver meu iCartão
                    </Link>
                    <br />
                  </div>
                  <center id="qrcodeTour">
                    <img src={`${process.env.REACT_APP_API_PHP_BASE_URL}qrcode/gerar_img.php?d=https://icartao.me/@${shelf.state.dadosPagina.slug}`} style={{ width: "50%" }} alt="QRCode" />
                    <br />
                    <button className="btn btn-painel-roxo align-middle" onClick={() => imprimirQrCode(shelf)}>
                      <i className="far fa-print"></i>
                    </button>
                  </center>
                </div>
              </center>
              <br />

            </div>

            <div className="col-sm-4 remove-mobile" scrolling="no">
              <button className="btn-link-copy" onClick={() => navigator.clipboard.writeText(`https://icartao.me/@${shelf.state.dadosPagina.slug}`)}>
                <i className="far fa-clone"></i>
              </button>
              {/* eslint-disable-next-line */}
              <a className="a-link" target="_blank" href={process.env.PUBLIC_URL + "/p/" + shelf.state.dadosPagina.slug}>icartao.me{"/@" + shelf.state.dadosPagina.slug}</a>
              <br /><br />

              <div className="imagem-preview preview-pagina">
                <PaginaUsuarioCartao
                  modoDev={{
                    state: {
                      predefinicoes: JSON.stringify(shelf?.state?.dadosPagina) || {}
                    }
                  }}
                  params={{
                    slug: "meu-icartao"
                  }}
                />
              </div>
              <br />
            </div>

          </div>
          <br />
        </div >



      </div >





    );
  }
}
