export default async function imprimirQrCode(shelf) {
  var html = `
  <center>
      <script>
          window.focus();
          window.print();
      </script>
      <h1>${shelf.state.dadosPagina.nome}</h1>
      <h3>Visite o iCartão de seu amigo escaneando o qrcode a baixo com a camera do seu celular ou usando o aplicativo do iCartão:</h3>
      <br/>
      <img src="${process.env.REACT_APP_API_PHP_BASE_URL}qrcode/gerar_img.php?d=https://icartao.me/@${shelf.state.dadosPagina.slug}" s style="width: 35%;" alt="QRCode" />
      <br/><br/>
      <img src="/img/logo.png" width="150px" style="border-radius: 20px;"/>
  </center>
  `;
  const popup = window.open('', 'PRINT');
  popup.document.write(html);
  popup.focus(); // necessary for IE >= 10*/
  popup.print();
  setTimeout(() => {
    popup.document.close(); // necessary for IE >= 10
    popup.close();
  }, 2000);
}