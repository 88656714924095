import { Component } from 'react';
import config from '../../../../../../config.js';
import ApisApp from '../../../../../apis/app.js';
import ApisStripeProduto from '../../../../../apis/stripeProduto.js';
import ApiUpload from '../../../../../apis/upload.js';
import html from './criar.jsx';
import pegarInfosUser from '../../../../../functions/pegarInfosUser.js';


export default class PaginaDevPainelAppsCriar extends Component {
  constructor(props) {
    super(props);
    this.formCriarApp = this.formCriarApp.bind(this, this);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this, true, "dev");
    await this.setState({
      carregando: false
    });
  }


  async formCriarApp(shelf, shelfHtml) {
    try {
      shelfHtml.preventDefault();
    } catch (error) { }
    let dadosFormFormado = {};
    let pegarDadoForm = new FormData(shelfHtml.target);
    pegarDadoForm.forEach((valor, chave) => {
      dadosFormFormado[chave] = valor;
    });

    // Criador Form get campos
    if (localStorage.getItem("CriadorForm")) {
      dadosFormFormado.formsConfigJson = JSON.parse(atob(localStorage.getItem("CriadorForm")));
    }
    if (dadosFormFormado.formsConfigJson?.[0]?.nomeSecao === "") {
      dadosFormFormado.formsConfigJson = [];
    }

    // Pegar arquivo e preparar para upload
    let pegarArquivo = dadosFormFormado.iconeFile;
    var formdataIcone = new FormData();
    formdataIcone.append("arquivo", pegarArquivo);
    delete dadosFormFormado.iconeFile;



    await shelf.setState({
      carregando: true
    })

    try {
      if (pegarArquivo?.name) {
        const respostaUpload = await ApiUpload.prototype.uploadArquivo(formdataIcone);
        if (respostaUpload.status === "OK") {
          dadosFormFormado["icone"] = respostaUpload.resposta.nome;
        } else {
          await shelf.setState({
            carregando: false,
            erro: respostaUpload.statusMotivo
          });
          return;
        }
      }

      dadosFormFormado["userId"] = shelf.state.dadosUser.id;
      const respostaPost = await ApisApp.post(dadosFormFormado);
      if (respostaPost.status === "OK") {

        let stripeDados = {
          data: {
            codeRef: `app-${respostaPost.resposta?.[0]?.id}`,
            nome: `App ${dadosFormFormado.nome} | Plano Anual`,
            preco: Number(`${dadosFormFormado.preco}00`),
            descricao: dadosFormFormado.descricao,
          }
        };
        if (dadosFormFormado.icone) {
          stripeDados.data.imagens = [`${config.arquivos.baseUrl}${dadosFormFormado.icone}`]
        }
        await ApisStripeProduto.criar(stripeDados);
        window.location.href = "/dev/painel/apps/editar/" + respostaPost.resposta?.[0]?.slug;

      } else {
        await shelf.setState({
          carregando: false,
          erro: respostaPost.statusMotivo
        })
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      })
    }

  }

  render() {
    return html(this);
  }
}