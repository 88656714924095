import React from 'react';
import LayoutError from '../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../layout/LayoutCarregando';
import PaginaUsuarioCartao from '../../../cartao/cartao';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaAppConfig container">


        <div className="row no-gutters">
          <div className="col-sm-8 coluna-form">
            {
              shelf.state?.app?.formsConfig?.[0] ? (<>
                <div className="">
                  <br /><br />

                  <form className="dados-form" id="formAppConfig" onSubmit={shelf.formEditarConfigAppInstalado}>
                    {
                      shelf.state?.app.formsConfig?.[0]?.campos.map((campo, iCampo) => (<>
                        <div key={iCampo}>
                          <label><p>{campo?.nome}</p></label>
                          {
                            campo?.tipo === "textarea" ? (<>
                              <textarea defaultValue={shelf.state?.appInstaladoDados?.configPreenchida?.[0]?.[shelf.state?.app?.id + "_" + campo?.slug]} name={shelf.state?.app?.id + "_" + campo?.slug} placeholder="Preencha.." className="form-control mb-3" required={campo?.obrigatorio} />
                            </>) : campo?.tipo === "radio" ? (<>
                            </>) : campo?.tipo === "checkbox" ? (<>
                            </>) : campo?.tipo === "select" ? (<>
                              <select defaultValue={shelf.state?.appInstaladoDados?.configPreenchida?.[0]?.[shelf.state?.app?.id + "_" + campo?.slug]} name={shelf.state?.app?.id + "_" + campo?.slug} required={campo?.obrigatorio} className="form-control mb-3" >
                                <option value="" selected disabled>Selecione uma opção</option>
                                {campo?.opcoes.map((opcao, iOpcao) => (
                                  <option value={opcao?.valor} acao={opcao?.acao}>{opcao?.valor}</option>
                                ))}
                              </select>
                            </>) : (<>
                              <input type={campo?.tipo} defaultValue={shelf.state?.appInstaladoDados?.configPreenchida?.[0]?.[shelf.state?.app?.id + "_" + campo?.slug]} name={shelf.state?.app?.id + "_" + campo?.slug} placeholder="Preencha.." className="form-control mb-3" required={campo?.obrigatorio} />
                            </>)
                          }
                          <small className="text-info">{campo?.dica}</small>
                          <br />
                        </div>
                      </>))
                    }

                    <br />
                    <center>
                      <button className="btn btn-painel-roxo" type="submit"><i class="fad fa-save"></i> Salvar</button>
                    </center>

                    <br />
                    <br />

                    <center>
                      <button className="btn btn-danger mt-5 mb-2" type="button" onClick={() => { shelf.desinstalarApp(shelf.state?.appInstaladoDados?.id, shelf) }}>
                        Desinstalar app
                      </button>
                    </center>

                    <br />
                  </form>
                </div>
              </>) : (<>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                  <div>
                    <h1 className="text-center">App sem config</h1>
                    <p className="text-center">Esse app não tem nenhum tipo de configuração.</p>
                  </div>
                </div>
              </>)
            }

          </div>



          <div className="col-sm-4 remove-mobile" scrolling="no">
            <div className="mt-3">
              <button className="btn-link-copy" onClick={() => navigator.clipboard.writeText(`https://icartao.me/@${shelf.state.dadosPagina.slug}`)}>
                <i className="far fa-clone"></i>
              </button>
              {/* eslint-disable-next-line */}
              <a className="a-link" target="_blank" href={process.env.PUBLIC_URL + "/p/" + shelf.state.dadosPagina.slug}>icartao.me{"/@" + shelf.state.dadosPagina.slug}</a>
              <br /><br />

              <div className="imagem-preview preview-pagina">
                <PaginaUsuarioCartao
                  modoDev={{
                    state: {
                      predefinicoes: JSON.stringify(shelf?.state?.dadosPagina) || {}
                    }
                  }}
                  params={{
                    slug: "meu-icartao"
                  }}
                />
              </div>
              <br />
            </div>
          </div>

        </div>
        <br />
      </div >
    );
  }
}
