import { Component } from 'react';
import ApisStripeCheckout from '../../apis/stripeCheckout.js';
import ApisPaginaApps from '../../apis/paginaApps.js';
import html from './cardApp.jsx';


export default class ComponenteCardApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    this.setState({
      carregando: false,
      erro: null
    });
  }

  async onClickInstalar(shelf, paginaId, app) {
    await shelf.setState({
      carregando: true
    })
    try {
      const respostaApisPaginaApps = await ApisPaginaApps.post({ paginaId, appId: app?.id });
      if (respostaApisPaginaApps.resposta) {
        window.location.href = "/usuario/painel/app/configurar/" + app?.slug;
      } else {
        await shelf.setState({
          carregando: false,
          erro: "Não conseguimos fazer a instalação desse app."
        })
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      });
    }
  }


  static async onClickComprarApp(shelf, app) {

    shelf.setState({ carregando: true, erro: null });
    try {
    
      let sessaoCheckout = await ApisStripeCheckout.criar({
        query: {
          codeRef: `app-${app?.id}`
        },
        data: {
          user: {
            id: shelf.props.dadosUser?.id,
            email: shelf.props.dadosUser?.email,
            nome: shelf.props.dadosUser?.nome
          },
          pagina: {
            id: shelf.props.dadosPagina.id,
            slug: shelf.props.dadosPagina.slug,
          }
        }
      });

      if (sessaoCheckout?.status === "OK") {
        window.open(sessaoCheckout?.resposta?.url, '_blank');
        await shelf.setState({ carregando: false });
      } else {
        throw new Error(sessaoCheckout?.statusMotivo);
      }
    } catch (error) {
      shelf.setState({ carregando: false, erro: String(error) });
    }

  }

  render() {
    return html(this);
  }
}