import React from 'react';
import LayoutError from '../../layout/LayoutError';
import LayoutCarregando from '../../layout/LayoutCarregando';
import config from '../../../config';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="ComponenteCardTema" key={`cardTema${shelf?.props?.key}`}>

        <div className="card card-loja-tema mb-3 p-1">
          <div className="row g-0">
            <div className="col-12 p-2">
              <h5 className="card-title limite-1linha-ch-100p mb-0" title={shelf?.props?.tema?.nome}>{shelf?.props?.tema?.nome}</h5>
            </div>
            <div className="col-12 col-md-6 d-grid align-content-center">
              {
                shelf?.props?.tema?.prints?.[0] && (
                  <img src={config.arquivos.baseUrl + shelf?.props?.tema?.prints?.[0]} className="w-100 img-print-tema" alt="Print do tema." />
                )
              }
            </div>
            <div className="col-12 col-md-6 p-2">
              <p className="card-text limite-height-10rem" title={shelf?.props?.tema?.descricao}><small className="text-muted">{shelf?.props?.tema?.descricao}</small></p>

              {
                (shelf.props.dadosUser?.temaId === shelf?.props?.tema?.id) ? (<>
                  <button disabled={shelf?.props?.disabled} className="btn btn-outline-danger text-start" onClick={() => { shelf.onClickDesintalarTema(shelf, shelf.props.dadosUser, shelf?.props?.tema) }}>
                    <span>Desinstalar</span>
                  </button>
                </>) : (<>
                  {
                    (shelf?.props?.dadosClienteStripe?.assinaturasAtivas?.findIndex(assinatura => assinatura.plan.produtoDetalhes.metadata.codeRef === `tema-${shelf?.props?.tema?.id}`) !== -1) ? (<>
                      <button disabled={shelf?.props?.disabled} className="btn btn-outline-secondary text-start" onClick={() => { shelf.onClickUsarTema(shelf, shelf.props.dadosUser?.token, shelf?.props?.tema) }}>
                        <span className="text-info">Comprado</span>
                        <br />
                        <span>Usar</span>
                      </button>
                    </>) : (<>
                      {Number(shelf?.props?.tema?.preco) === 0 ? (<>
                        <button disabled={shelf?.props?.disabled} className="btn btn-outline-secondary text-start" onClick={() => { shelf.onClickUsarTema(shelf, shelf.props.dadosUser?.token, shelf?.props?.tema) }}>
                          <span className="text-success">Grátis</span>
                          <br />
                          <span>Usar</span>
                        </button>
                      </>) : (<>
                        <button disabled={shelf?.props?.disabled} className="btn btn-outline-secondary text-start" onClick={() => { shelf.onClickComprarTema(shelf, shelf.props.dadosUser, shelf?.props?.tema) }}>
                          <span className="text-info">{Number(shelf?.props?.tema?.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                          <br />
                          <span>Comprar</span>
                        </button>
                      </>)}
                    </>)
                  }
                </>)
              }

            </div>
          </div>
        </div>

      </div >
    );
  }
}
