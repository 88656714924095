import React from 'react';
import LayoutError from '../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../layout/LayoutCarregando';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaDevTemas">
        <div className="container">
          <br /><br />

          <form className="dados-form" id="formCriartemas" onSubmit={shelf.formCriarTemas} >
            <label htmlFor="nome"><p>Nome:</p></label>
            <input type="text" className="form-control form-card" name="nome" id="nome" placeholder="Nome " required />
            <br />


            <label htmlFor="PrintFile"><p>Print:</p></label>
            <input type="file" accept="image/gif, image/png" max="1" className="form-control form-card" name="PrintFile" id="PrintFile" />
            <small className="text-info">Tamanho da imagem de no maximo 612px por 1280px, deve ser .png ou .gif, cortaremos um pouco as bordas.</small>
            <br /><br />

            <label htmlFor="descricao"><p>Descrição :</p></label>
            <textarea className="form-control form-card" name="descricao" id="descricao" placeholder="Descrição breve do seu tema." required></textarea>
            <br />

            <label htmlFor="preco"><p>Preço:</p></label>
            <input type="number" min="0" max="10" className="form-control form-card" name="preco" id="preco" placeholder="Preço " required />
            <br />


            <br />
            <br />

            <center><button type="submit" className="btn btn-painel-roxo btn-lg"><i class="fad fa-save"></i> Criar tema</button></center>


            <br />
            <br />
            
          </form>

        </div >
      </div >
    );
  }
}
