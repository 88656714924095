import React from 'react';
import LayoutError from '../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../layout/LayoutCarregando';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <>
        <form id="formDadosCriarPagina" onSubmit={shelf.pegarDadosFormCriarPagina}>
          <div className="container mt-5">
            <label htmlFor="nome"><p>Nome da Página</p></label>
            <input type="text" className="form-control form-card" name="nome" id="nome" placeholder="Nome da página" required />

            <div className='d-flex justify-content-center mt-5'>
              <button type="submit" className="btn btn-painel-roxo btn-lg"><i class="fad fa-plus"></i> Criar</button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
