export default class ApisUser {

    static validarLoginUser(dadosForm) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dadosForm);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/login/post.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static editarUser(dadosForm, userToken) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dadosForm);

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/put.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&userToken=" + userToken, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static pegarUserPorToken(userToken) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit=1&userToken=" + userToken, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static pegarUserPorSlug(slug) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit=1&slug=" + slug, requestOptions)
                .then(async response => {
                    let json = await response.json();
                    try {
                        Promise.all(json?.resposta?.forEach(user => {
                            user.tema.codigoCss = atob(user?.tema?.codigoCss);
                        }));
                    } catch (error) {}
                    resposta(json);
                })
                .catch(err => error(err));

        });
    }


    static pegarUserPorEmail(email) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit=1&email=" + email, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static criarUser(formDadosUsuario) {
        return new Promise((resposta, error) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(formDadosUsuario);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/post.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));
        });
    }

}
