import React from 'react';
import LayoutError from '../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../layout/LayoutCarregando';
import ReactJson from 'react-json-view';
import ComponenteCardApp from '../../../../../componentes/cardApp/cardApp';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaDevApps">
        <div className="container">
          <br /><br />

          <form className="dados-form" id="formDadosEditarUser" onSubmit={shelf.formEditarApp} >
            <label htmlFor="nome"><p>Nome:</p></label>
            <input defaultValue={shelf.state?.app?.nome} type="text" className="form-control form-card" name="nome" id="nome" placeholder="Nome " required />
            <br />


            <label htmlFor="iconeFile"><p>Icone:</p></label>
            <input type="file" accept="image/png" max="1" className="form-control form-card" name="iconeFile" id="iconeFile" />
            <small className="text-warning">Só escolha um arquivo nesse campo caso queira alterar o icone.</small>
            <br /><br />

            <label htmlFor="descricao"><p>Descrição:</p></label>
            <textarea className="form-control form-card" name="descricao" id="descricao" defaultValue={shelf.state?.app?.descricao} placeholder="Descrição breve do seu aplicativo."></textarea>
            <br />

            <label htmlFor="preco"><p>Preço:</p></label>
            <input type="number" min="0" max="20" className="form-control form-card" name="preco" id="preco" placeholder="Preço " defaultValue={shelf.state?.app?.preco} required />
            <br />

            <label htmlFor="status"><p>Status:</p></label>
            <select name="status" id="status" className="form-control form-card" defaultValue={shelf.state?.app?.status} required="required">
              <option value="rascunho">Rascunho</option>
              <option value="publicado">Publicado</option>
              <option value="recusado">Recusado</option>
            </select>

            <br />
            <br />

            <center><button type="submit" className="btn btn-painel-roxo btn-lg"><i class="fad fa-save"></i> Atualizar app</button></center>


          </form>

          <div className="exemploExibicao mt-5">
            <h2>Exemplo de exibição do app na loja:</h2>

            <ComponenteCardApp
              app={shelf.state?.app}
              disabled={true}
            />

          </div>

          <div className="slugDosCamposCodigo mt-5">
            <h2>Exemplo de resposta da API get user:</h2>

            <ReactJson name={false} displayDataTypes={false} src={shelf.state?.slugCamposJSON} />
          </div>

          
          <div className="slugDosCamposTabela mt-5">
            <h2>Slug dos campos no banco de dados:</h2>

            <table className="table table-striped table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col">Slug</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Seção</th>
                </tr>
              </thead>
              <tbody>
                {
                  shelf.state?.app?.formsConfig.map((secao) => (<>
                    {
                      secao?.campos.map((campo) => (<>
                        <tr>
                          <th scope="row">{`${shelf.state?.app?.id}_${campo?.slug}`}</th>
                          <td>{campo?.nome}</td>
                          <td>{secao?.nomeSecao}</td>
                        </tr>
                      </>))
                    }
                  </>
                  ))
                }
              </tbody>

            </table>

          </div>

          <br />
          <br />

        </div >
      </div >
    );
  }
}
