export default class ApisPaginas {

    static editar(dadosForm, id) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dadosForm);

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/put.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&id=" + id, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static obterPorSlug(slug) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit=1&slug=" + slug, requestOptions)
                .then(async response => {
                    let json = await response.json();
                    try {
                        await Promise.all(json?.resposta?.forEach(pagina => {
                            pagina.tema.codigoCss = atob(pagina?.tema?.codigoCss);
                        }));
                    } catch (error) {}
                    resposta(json);
                })
                .catch(err => error(err));

        });
    }

    static obterPorId(id) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit=1&id=" + id, requestOptions)
                .then(async response => {
                    let json = await response.json();
                    try {
                        await Promise.all(json?.resposta?.forEach(pagina => {
                            pagina.tema.codigoCss = atob(pagina?.tema?.codigoCss);
                        }));
                    } catch (error) {}
                    resposta(json);
                })
                .catch(err => error(err));

        });
    }

    static criar(formDadosUsuario) {
        return new Promise((resposta, error) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(formDadosUsuario);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/post.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));
        });
    }

}
