import { Component } from 'react';
import LayoutCarregando from '../layout/LayoutCarregando.js';
import $ from 'jquery';

export default class PaginaIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      setTimeout(function () {
        window.location.href = `${process.env.REACT_APP_API_NODE_BASE_URL}/privada/usuario/logar`;
      }, 500);
    });
  }

  render() {
    return (<>
      <LayoutCarregando />
    </>);
  }
}