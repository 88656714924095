import { Component } from 'react';
import html from './temas.jsx';
import ApisTemas from '../../../../apis/temas.js';
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';


export default class PaginaDevPainelTemas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this, true, "dev");
    try {
      let temas = await ApisTemas.getAllUser(50, this.state?.dadosUser?.id);
      await this.setState({
        carregando: false,
        temas: temas.resposta?.reverse()
      });
    } catch (error) {
      await this.setState({
        carregando: false,
        erro: error
      });
    }
  }

  render() {
    return html(this);
  }
}