import paginaEditar from "./paginaEditar";
import $ from 'jquery';

export default async function pegarDadosFormAtualizarPagina(shelf, shelfHtml) {
  try {
    shelfHtml.preventDefault();
  } catch (error) { }
  let dadosFormFormado = {};
  let pegarDadoForm = new FormData(shelfHtml.target);
  pegarDadoForm.forEach((valor, chave) => {
    dadosFormFormado[chave] = valor;
  });

  let cardLinkMove = $(".card-link-move");
  let links = [];
  cardLinkMove.each(function (i) {
    let p = $(this).attr("pAntiga");
    links.push({
      posicao: parseInt($("[name='posicao-" + p + "']").val()),
      titulo: dadosFormFormado["titulo-" + p],
      link: dadosFormFormado["link-" + p],
      icone: dadosFormFormado["icone-" + p]
    });
  });
  dadosFormFormado.links = links;

  await paginaEditar(shelf, dadosFormFormado, "SEM_MODAL", true);
}