import ApisPaginas from "../apis/paginas";
import pegarInfosPagina from "./pegarInfosPagina";
import pegarInfosUser from "./pegarInfosUser";

export default async function paginaEditar(shelf, dados, titulo = 'Ok, Página editada com sucesso!', reload = false) {
  return new Promise(async (resolve, reject) => {

    await shelf.setState({
      carregando: true,
      erro: null
    });


    try {
      if (shelf.state.ocultarWhats === true) {
        dados.link_whatsapp = dados.link_whatsapp.replace(/\D/gim, '');
        if (String(dados.link_whatsapp) !== "") {
          dados.link_whatsapp = "https://api.whatsapp.com/send?phone=55" + dados.link_whatsapp;
        }
      } else {
        dados.link_whatsapp = "WHATS_OCULTO";
      }
      dados.links = dados.links.sort(function (a, b) {
        return a.posicao < b.posicao ? -1 : a.posicao > b.posicao ? 1 : 0;
      });
    } catch (error) { }



    try {
      const respostaEdicao = await ApisPaginas.editar(dados, localStorage.getItem("paginaId"));
      if (respostaEdicao.status === "OK") {

        if (reload === false) {
          await pegarInfosUser(shelf);
          await pegarInfosPagina(shelf);

          await shelf.setState({
            carregando: false,
            erro: null
          });
        }

        if (reload === true) {
          var url = window.location.href.split("?msg=");
          window.location.href = url[0] + "?msg=" + respostaEdicao.statusMotivo;
        }

        resolve(titulo)
      } else {
        await shelf.setState({
          carregando: false,
          erro: respostaEdicao.statusMotivo
        })
        reject(respostaEdicao.statusMotivo)
      }
    } catch (error) {
      shelf.setState({
        carregando: false,
        erro: error
      })
      reject(error)
    }
  });
}