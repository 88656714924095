import React from 'react';
import { Component } from 'react';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import * as Funcoes from '../../../../../funcoes'

export default class PaginaUsuarioPainelSair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    Funcoes.sairDaConta();
  }

  render() {
    return (
      <LayoutCarregando />
    );
  }
}