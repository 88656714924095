import React from 'react';
import { Link } from 'react-router';
import LayoutError from '../../layout/LayoutError';
import LayoutCarregando from '../../layout/LayoutCarregando';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="pagina404">
        <div className="container">
          <center>
            <br />
            <br />
            <br />

            <h1>Erro 404</h1>
            <p>Essa pagina não existe</p>


            <Link to="/" className="btn btn-painel-roxo">Retorne para página inicial</Link>
          </center>
        </div>

      </div>
    );
  }
}
