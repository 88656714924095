import React from 'react';
import { Component } from 'react';
import $ from 'jquery';
import paginaLinkShare from '../../../../functions/paginaLinkShare';
import pegarInfosUser from '../../../../functions/pegarInfosUser';
import pegarInfosPagina from '../../../../functions/pegarInfosPagina';

export default class PaginaUsuarioPainelCompartilhar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      setTimeout(() => {
        $("#btnCompartilhar").click();
      }, 100);
    });
  }
  
  async shareLink() {
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    await paginaLinkShare();
  }

  render() {
    return (<>
      <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
        <div>
          <p className="text-center">Caso não abrir o menu de compartilhamento automatico toque no botão abaixo:</p>
          <center><button className="btn btn-painel-roxo text-center" id="btnCompartilhar" onClick={() => { this.shareLink() }}>Compartilhar</button></center>
        </div>
      </div>
    </>
    );
  }
}