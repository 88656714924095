import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Router, Route, browserHistory, Redirect } from '@version/react-router-v3';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'animate.css/source/animate.css';
import './app/estilo/global.css';

//  importando paginas
import PaginaUsuarioCartao from './app/paginas/usuario/cartao/cartao';
import Pagina404 from './app/paginas/404/404';
import LayoutModeloNavegacao from './app/layout/navegacao';
import LayoutModeloVazio from './app/layout/vazio';
import PaginaUsuarioPainelSair from './app/paginas/usuario/painel/sair/sair';
import PaginaUsuarioPainelInicio from './app/paginas/usuario/painel/inicio/inicio';
import PaginaUsuarioPainelLinks from './app/paginas/usuario/painel/links/links';
import PaginaUsuarioPainelPersonalizar from './app/paginas/usuario/painel/personalizar/personalizar';
import PaginaUsuarioPainelVerMeuCartao from './app/paginas/usuario/painel/verMeuCartao/verMeuCartao';
import PaginaUsuarioPainelLoja from './app/paginas/usuario/painel/loja/loja';
import PaginaDevPainelInicio from './app/paginas/dev/painel/inicio/inicio';
import PaginaDevPainelApps from './app/paginas/dev/painel/apps/apps';
import PaginaDevPainelAppsCriar from './app/paginas/dev/painel/apps/criar/criar';
import PaginaDevPainelAppsEditar from './app/paginas/dev/painel/apps/editar/editar';
import PaginaUsuarioPainelAppConfigurar from './app/paginas/usuario/painel/app/configurar/configurar';
import PaginaUsuarioPainelCompartilhar from './app/paginas/usuario/painel/compartilhar/compartilhar';
import PaginaIndex from './app/paginas';
import PaginaDevPainelTemas from './app/paginas/dev/painel/temas/temas';
import PaginaDevPainelTemasCriar from './app/paginas/dev/painel/temas/criar/criar';
import PaginaDevPainelTemasEditar from './app/paginas/dev/painel/temas/editar/editar';
import PaginaUsuarioPainelLojaPagamentoSucesso from './app/paginas/usuario/painel/loja/pagamento/sucesso/sucesso';
import PaginaLogando from './app/paginas/logando/logando';
import PaginaUsuarioPainelPaginas from './app/paginas/usuario/painel/paginas/paginas';
import PaginaUsuarioPainelPaginasCriar from './app/paginas/usuario/painel/paginas/criar/criar';




ReactDOM.render(
  <React.StrictMode>
    <Router history={browserHistory}>

      <Route component={LayoutModeloNavegacao}>
        <Redirect from='/paginaUsuario' to="/usuario/painel/inicio" />
        <Redirect from='/painel/usuario' to="/usuario/painel/inicio" />
        <Redirect from='/usuario/painel' to="/usuario/painel/inicio" />
        <Redirect from='/paginaRegistro' to="/registre-se" />
        <Redirect from='/dev' to="/dev/painel/inicio" />
        <Redirect from='/logar' to="/" />
        <Redirect from='/login' to="/" />
        <Redirect from='/usuario/senha/recuperar' to="/" />
        <Redirect from='/usuario/painel/conta' to="/usuario/painel/personaliza" />

        <Route path='/' nomePagina="Carregando" component={PaginaIndex} />
        <Route path='/404' nomePagina="404" component={Pagina404} />
        <Route path='/logando' nomePagina="Login" component={PaginaLogando} />

        <Route path='/dev/painel/inicio' nomePagina="Início" component={PaginaDevPainelInicio} />
        <Route path='/dev/painel/apps' nomePagina="Apps" component={PaginaDevPainelApps} />
        <Route path='/dev/painel/apps/criar' nomePagina="Criar app" component={PaginaDevPainelAppsCriar} />
        <Route path='/dev/painel/apps/editar/:slug' nomePagina="Editar app" component={PaginaDevPainelAppsEditar} />
        <Route path='/dev/painel/temas' nomePagina="Temas" component={PaginaDevPainelTemas} />
        <Route path='/dev/painel/temas/criar' nomePagina="Criar tema" component={PaginaDevPainelTemasCriar} />
        <Route path='/dev/painel/temas/editar/:slug' nomePagina="Editar tema" component={PaginaDevPainelTemasEditar} />
        
        <Route path='/usuario/painel/paginas' nomePagina="Páginas" component={PaginaUsuarioPainelPaginas} />
        <Route path='/usuario/painel/paginas/criar' nomePagina="Criar Página" component={PaginaUsuarioPainelPaginasCriar} />
        <Route path='/usuario/painel/inicio' nomePagina="Início" component={PaginaUsuarioPainelInicio} />
        <Route path='/usuario/painel/links' nomePagina="Links" component={PaginaUsuarioPainelLinks} />
        <Route path='/usuario/painel/personalizar' nomePagina="Personalizar" component={PaginaUsuarioPainelPersonalizar} />
        <Route path='/usuario/painel/loja' nomePagina="Loja" component={PaginaUsuarioPainelLoja} />
        <Route path='/usuario/painel/loja/pagamento/sucesso' nomePagina="Sucesso" component={PaginaUsuarioPainelLojaPagamentoSucesso} />
        <Route path='/usuario/painel/sair' nomePagina="Sair" component={PaginaUsuarioPainelSair} />
        <Route path='/usuario/painel/compartilhar' nomePagina="Compartilhar" component={PaginaUsuarioPainelCompartilhar} />
        <Route path='/usuario/painel/ver-meu-icartao' nomePagina="Ver meu iCartão" component={PaginaUsuarioPainelVerMeuCartao} />
        <Route path='/usuario/painel/app/configurar/:slug' nomePagina="Config app" component={PaginaUsuarioPainelAppConfigurar} />
      </Route>

      <Route component={LayoutModeloVazio}>
        <Route path='/@:slug' nomePagina="iCartão" component={PaginaUsuarioCartao} />
        <Redirect from='/p/:slug' to="/@:slug" />
        <Redirect from='/*' to="/404" />
      </Route>

    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
