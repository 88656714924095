import { Component } from 'react';
import LayoutCarregando from '../../layout/LayoutCarregando.js';
import LayoutError from '../../layout/LayoutError.js';

export default class PaginaLogando extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  // Função nativa do React que se executa logo quando a pagina carrega
  async componentDidMount() {
    window.scrollTo(0, 0);
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('tk');

    if (String(tokenParam).length > 5) {
      localStorage.setItem('userToken', tokenParam);
      window.location.href = '/paginaUsuario';
    }else{
      localStorage.removeItem("userToken");
      window.location.href = '/';
    }
  }


  render() {

    if (this.state.erro !== null) {
      return (<LayoutError erro={this.state.erro}></LayoutError>);
    } else if (this.state.carregando === true) {
      return (<LayoutCarregando></LayoutCarregando>);
    } else {

      return (<>
        <LayoutCarregando />
      </>);

    }
  }
}