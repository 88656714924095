import { Component } from 'react';
import html from './personalizar.jsx';
import ApiUpload from '../../../../apis/upload.js';
import $ from 'jquery';
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';
import pegarInfosPagina from '../../../../functions/pegarInfosPagina.js';
import paginaEditar from '../../../../functions/paginaEditar.js';
import pegarDadosFormAtualizarPagina from '../../../../functions/pegarDadosFormAtualizarPagina.js';


export default class PaginaUsuarioPainelPersonalizar extends Component {
  constructor(props) {
    super(props);
    this.pegarFotoFazerUpload = this.pegarFotoFazerUpload.bind(this, this);
    this.pegarDadosFormAtualizarPagina = pegarDadosFormAtualizarPagina.bind(this, this);
    this.state = {
      erro: null,
      carregando: true,
      ocultarWhats: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    await this.setState({
      carregando: false,
      pMsg: this.props.location.query.msg
    });

    $(".card-" + this.state.dadosPagina.modelo).addClass("selecionado");
    setTimeout(() => {
      $("input[autoComplete='off']").val("");
    }, 1500);
  }

  selecionarModelo(modelo) {
    this.setState({ dadosPagina: { ...this.state.dadosPagina, modelo } });
    $(document).ready(function () {
      $("input[name='modelo']").val(modelo);
      $(".galeria-temas .selecionado").removeClass("selecionado");
      $(".card-" + modelo).addClass("selecionado");
    });
  }

  async usarTemasPadroes(shelf) {
    let dadosFormFormado = {};
    dadosFormFormado["temaId"] = "null";
    await paginaEditar(shelf, dadosFormFormado, "SEM_MODAL", true);
  }

  async pegarFotoFazerUpload(shelf, shelfHtml) {
    shelfHtml.preventDefault();
    let pegarCaminhoArquivo = shelfHtml.target.value;
    let pegarArquivo = shelfHtml.target.files[0];
    var formdata = new FormData();
    formdata.append("arquivo", pegarArquivo, pegarCaminhoArquivo);

    await shelf.setState({
      carregando: true,
      erro: null
    });

    if (pegarArquivo.size > (5 * 1024 * 1024)) {
      await shelf.setState({
        carregando: true,
        erro: "O arquivo seleciona é muito grande deve ter menos do que 5mb."
      });
    } else {
      try {
        const respostaUpload = await ApiUpload.prototype.uploadArquivo(formdata);
        if (respostaUpload.status === "OK") {

          let dadosEdicaoUser = {};
          dadosEdicaoUser["foto"] = respostaUpload.resposta.nome;

          await paginaEditar(shelf, dadosEdicaoUser, "Foto alterada!");

        } else {
          await shelf.setState({
            carregando: false,
            erro: respostaUpload.statusMotivo
          })
        }
      } catch (error) {
        await shelf.setState({
          carregando: false,
          erro: error
        })
      }
    }

  }

  render() {
    return html(this);
  }
}