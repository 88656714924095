import { Component } from 'react';
import html from './apps.jsx';
import ApisApp from '../../../../apis/app.js';
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';


export default class PaginaDevPainelApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this, true, "dev");
    try {
      let apps = await ApisApp.getAllUser(50, this.state?.dadosUser?.id);
      await this.setState({
        carregando: false,
        apps: apps.resposta?.reverse()
      });
    } catch (error) {
      await this.setState({
        carregando: false,
        erro: error
      });
    }
  }

  render() {
    return html(this);
  }
}