import { Component } from 'react';
import html from './404.jsx';

export default class Pagina404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.setState({
      carregando: false
    });
  }

  render() {
    return html(this);
  }
}