import React, { Component } from 'react';

export default class LayoutError extends Component {

  render() {
    return (
      <div className="page-error animated fadeIn slow">
        <center>
          <br />
          <br />
          <br />
          <i className="animated infinite tada fa fa-times fa-3x"></i>
          <h3>{String(this.props.erro)}</h3>
          <br/>
          <button onClick={()=>{window.location.reload()}} className="btn btn-dark btn-lg" > Tentar novamente! </button>
        </center>
      </div>
    )
  }
}
