export default class ApiUpload {

    uploadArquivo(dadosForm) {
        return new Promise((resposta, error) => {

                var requestOptions = {
                  method: 'POST',
                  body: dadosForm,
                  redirect: 'follow'
                };
                
                fetch(process.env.REACT_APP_API_PHP_BASE_URL + "upload.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

}
