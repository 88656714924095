import { Component } from 'react';
import html from './loja.jsx';
import $ from 'jquery';
import ApisApp from '../../../../apis/app.js';
import ApisPaginaApps from '../../../../apis/paginaApps.js';
import ApisTemas from '../../../../apis/temas.js';
import ApisStripePortalCliente from '../../../../apis/stripePortalCliente.js';
import ApisStripeCliente from '../../../../apis/stripeCliente.js';
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';
import pegarInfosPagina from '../../../../functions/pegarInfosPagina.js';


export default class PaginaUsuarioPainelLoja extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    try {
      let apps = (await ApisApp.getAll(50)).resposta?.reverse();
      let temas = (await ApisTemas.getAll(50)).resposta?.reverse();
      let appsInstaladosRes = (await ApisPaginaApps.getPaginaId(50, this.state?.dadosPagina?.id)).resposta?.reverse();
      let dadosClienteStripe = await ApisStripeCliente.obter({ query: { paginaId: this.state?.dadosPagina?.id } });
      let appsInstalados = [];


      if (dadosClienteStripe?.status === "OK") {
        dadosClienteStripe = dadosClienteStripe?.resposta;
      } else {
        dadosClienteStripe = { assinaturasAtivas: [] };
      }

      await Promise.all(apps.map(async (app, index) => {
        try {
          let filter = await Promise.all(appsInstaladosRes.filter(function (obj) { return String(obj.appId) === String(app?.id); }));
          if (filter[0]) {
            apps[index]["instalado"] = true;
            appsInstalados.push(apps[index]);
          } else {
            apps[index]["instalado"] = false;
          }
        } catch (error) {
          apps[index]["instalado"] = false;
        }
      }));
      let states = {
        carregando: false,
        apps,
        appsInstalados,
        temas,
        dadosClienteStripe
      };
      console.log("🚀 ~ file: loja.js:53 ~ PaginaUsuarioPainelLoja ~ componentDidMount ~ states:", states)
      await this.setState(states);
    } catch (error) {
      console.error(error);
      await this.setState({
        carregando: false,
        erro: String(error)
      });
    }
    $(document).ready(() => {
      if (window.location.hash) {
        $(window.location.hash + "-tab").addClass("active");
        $(window.location.hash).addClass("active");
        $(window.location.hash).addClass("show");
      } else {
        $("#apps-tab").addClass("active");
        $("#apps").addClass("active");
        $("#apps").addClass("show");
      }
    });
  }

  async onClickMinhasCompras(shelf) {
    await shelf.setState({
      carregando: true
    });
    try {
      let sessaoPortal = await ApisStripePortalCliente.criar({
        data: {
          user: {
            id: shelf.state.dadosUser?.id,
            email: shelf.state.dadosUser?.email,
            nome: shelf.state.dadosUser?.nome
          },
          pagina: {
            id: shelf.state.dadosPagina?.id,
            slug: shelf.state.dadosPagina?.slug,
          }
        }
      });
      if (sessaoPortal.status === "OK") {
        window.location.href = sessaoPortal.resposta.url;
      } else {
        throw new Error(sessaoPortal.statusMotivo);
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      });
    }
  }

  render() {
    return html(this);
  }
}