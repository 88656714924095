import { Component } from 'react';
import html from './editar.jsx';
import ApisApp from '../../../../../apis/app.js';
import ApiUpload from '../../../../../apis/upload.js';
import ApisStripeProduto from '../../../../../apis/stripeProduto.js';
import config from '../../../../../../config.js';
import pegarInfosUser from '../../../../../functions/pegarInfosUser.js';


export default class PaginaDevPainelAppsEditar extends Component {
  constructor(props) {
    super(props);
    this.formEditarApp = this.formEditarApp.bind(this, this);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this, true, "dev");
    try {
      if (this.props.params.slug) {
        let app = await ApisApp.getSlug(this.props.params.slug);
        let slugCamposJSON = { nome: "Nome do usuario", preco: 0, paginaAppsConfig: {} };
        app = app.resposta[0];
        // eslint-disable-next-line
        app.formsConfig.map(function (secao) {
          // eslint-disable-next-line
          secao.campos.map(function (campo) {
            slugCamposJSON["paginaAppsConfig"][`${app?.id}_${campo?.slug}`] = "Informação que o usuario preencher.";
          })
        })
        await this.setState({
          carregando: false,
          app,
          slugCamposJSON
        });
      } else {
        await this.setState({
          carregando: false
        });
      }
    } catch (error) {
      await this.setState({
        carregando: false,
        erro: error
      });
    }
  }


  async formEditarApp(shelf, shelfHtml) {
    try {
      shelfHtml.preventDefault();
    } catch (error) { }
    let dadosFormFormado = {};
    let pegarDadoForm = new FormData(shelfHtml.target);
    pegarDadoForm.forEach((valor, chave) => {
      dadosFormFormado[chave] = valor;
    });

    // Pegar arquivo e preparar para upload
    let pegarArquivo = dadosFormFormado.iconeFile;
    var formdataIcone = new FormData();
    formdataIcone.append("arquivo", pegarArquivo);
    delete dadosFormFormado.iconeFile;



    await shelf.setState({
      carregando: true
    })

    try {
      if (pegarArquivo?.name) {
        const respostaUpload = await ApiUpload.prototype.uploadArquivo(formdataIcone);
        if (respostaUpload.status === "OK") {
          dadosFormFormado["icone"] = respostaUpload.resposta.nome;
        } else {
          await shelf.setState({
            carregando: false,
            erro: respostaUpload.statusMotivo
          });
          return;
        }
      }

      const respostaPost = await ApisApp.put(dadosFormFormado, shelf.state?.app?.id);
      if (respostaPost.status === "OK") {

        let stripeDados = {
          query: {
            codeRef: `app-${shelf.state?.app?.id}`,
          },
          data: {
            nome: `App ${dadosFormFormado.nome} | Plano Anual`,
            preco: Number(`${dadosFormFormado.preco}00`),
            descricao: dadosFormFormado.descricao,
          }
        };
        if(shelf.state?.app?.icone){
          stripeDados.data.imagens = [`${config.arquivos.baseUrl}${shelf.state?.app?.icone}`]
        }
        if (dadosFormFormado.icone) {
          stripeDados.data.imagens = [`${config.arquivos.baseUrl}${dadosFormFormado.icone}`]
        }
        await ApisStripeProduto.editar(stripeDados);
        window.location.reload();

      } else {
        await shelf.setState({
          carregando: false,
          erro: respostaPost.statusMotivo
        })
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      })
    }

  }

  render() {
    return html(this);
  }
}