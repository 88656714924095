import ApisUser from "../apis/user";
import stringToBase64 from "./stringToBase64";

export default async function pegarInfosUser(shelf, deslogarUser = true, checkPermisao = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const respostaDadosUser = await ApisUser.pegarUserPorToken(localStorage.getItem("userToken"));
      if (respostaDadosUser.resposta) {
        let dadosUser = respostaDadosUser.resposta[0];
        try {
          dadosUser.link_whatsapp = dadosUser.link_whatsapp.replace("https://api.whatsapp.com/send?phone=55", "");
        } catch (err) { }
        
        if (checkPermisao) {
          if ((dadosUser?.permisao !== "admin") && (checkPermisao !== dadosUser?.permisao)) {
            await shelf.setState({
              carregando: false,
              erro: "Usuario não tem permissão suficiente para acessar essa página!"
            })
            reject("Usuario não tem permissão suficiente para acessar essa página!");
          }
        }

        await shelf.setState({ dadosUser });
        localStorage.setItem("dadosUser", await stringToBase64(JSON.stringify(dadosUser)));
        resolve(dadosUser);
      } else {
        if (deslogarUser) {
          await shelf.setState({
            carregando: false,
            erro: "Usuario não logado, redirecionando para pagina de login..."
          })
          window.location.href = "/logar";
          localStorage.removeItem("dadosUser");
          reject(respostaDadosUser.statusMotivo);
          console.log("🚀 ~ file: inicio.js:77 ~ PaginaUsuarioPainelInicio ~ returnnewPromise ~ respostaDadosUser.statusMotivo:", respostaDadosUser.statusMotivo)
        } else {
          resolve({});
        }
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      });
      if (deslogarUser) {
        window.location.href = "/logar";
        localStorage.removeItem("dadosUser");
      }
      reject(error);
    }
  });
}