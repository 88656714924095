import React from 'react';
import LayoutError from '../../../layout/LayoutError';
import LayoutCarregando from '../../../layout/LayoutCarregando';
import MetaTags from 'react-meta-tags';
import config from '../../../../config';
import TemaPadrao from '../../../temas/padrao/padrao';
import TemaBarMenus from '../../../temas/barMenus/barMenus';
import TemaQuadrados from '../../../temas/quadrados/quadrados';
import * as Funcoes from '../../../../funcoes';
import { Link } from '@version/react-router-v3';

export class MetaTagsUsuarioCartao extends React.Component {

  render() {
    let shelf = this.props.shelf;
    return (<>
      <MetaTags>
        <title>{shelf.state.dadosPagina.nome + " | " + config.site.titulo + " - " + config.site.tituloDesc}</title>
        <meta property="og:title" content={shelf.state.dadosPagina.nome + " - " + config.site.titulo} />
        <meta name="description" content={shelf.state.dadosPagina.sobre + " - " + config.site.tituloDesc} />
        <meta name="keywords" content={shelf.state.dadosPagina.nome + ", Cartão De Visita Digital"} />
        <meta name="theme-color" content={shelf.converterNomeCorHex(shelf.state.dadosPagina.cor)} />
        <meta name="msapplication-navbutton-color" content={shelf.converterNomeCorHex(shelf.state.dadosPagina.cor)} />
        <meta name="apple-mobile-web-app-status-bar-style" content={shelf.converterNomeCorHex(shelf.state.dadosPagina.cor)} />
        <meta name="msapplication-TileColor" content={shelf.converterNomeCorHex(shelf.state.dadosPagina.cor)} />
      </MetaTags>
      {
        ((shelf.state.dadosPagina?.tema?.codigoCss) || (shelf.props?.modoDev?.state?.codigoCss)) && (
          <div dangerouslySetInnerHTML={{
            __html: `
            <!-- Style Customizada do tema -->
              <style>
                ${shelf.props?.modoDev?.state?.codigoCss ? Funcoes.replaceAll(shelf.props?.modoDev?.state?.codigoCss, "</", "") : ""}
                ${shelf.state.dadosPagina?.tema?.codigoCss ? Funcoes.replaceAll(shelf.state.dadosPagina?.tema?.codigoCss, "</", "") : ""}
              </style>
          `}} />
        )
      }
      {
        (shelf.state.dadosPagina?.paginaAppsConfig?.["3_identificacaodopixel00"]) && (
          <div dangerouslySetInnerHTML={{
            __html: `
            <!-- Facebook Pixel Code -->
              <img height="1" width="1" style="display:none" 
                  src="https://www.facebook.com/tr?id=${shelf.state.dadosPagina?.paginaAppsConfig?.["3_identificacaodopixel00"]}&ev=PageView&noscript=1"/>
            <!-- End Facebook Pixel Code -->
          `}} />
        )
      }
      {
        (shelf.state.dadosPagina?.paginaAppsConfig?.["4_codigodeacompanhamento00"]) && (
          <div dangerouslySetInnerHTML={{
            __html: `
            <!-- Google Analytics -->
              <img height="1" width="1" style="display:none" 
                  src="https://ga-dev-tools.web.app/hit-builder/?v=1&t=pageview&tid=${shelf.state.dadosPagina?.paginaAppsConfig?.["4_codigodeacompanhamento00"]}&cid=noScript&dp=${window.location.pathname}"/>
          `}} />
        )
      }
    </>)
  }
}

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    switch (shelf.state.dadosPagina.modelo) {

      case "QUADRADOS":
        return (
          <div className="paginaCartao">
            {
              (shelf?.state?.dadosPagina) && (<>
                <MetaTagsUsuarioCartao shelf={shelf} />
                {
                  ((shelf?.state?.usuarioLogadoDonoPagina) && (!shelf.props?.modoDev)) && (<>
                    <Link to="/usuario/painel/personalizar" className="btn btn-roxo btn-editar-cartao"><i class="fad fa-paint-brush-alt"></i></Link>
                  </>)
                }
                <TemaQuadrados dadosPagina={shelf?.state?.dadosPagina} dadosUser={shelf?.state?.dadosUser} usuarioLogadoDonoPagina={shelf?.state?.usuarioLogadoDonoPagina} modoDev={shelf.props?.modoDev} />
              </>)
            }
          </div>
        )

      case "BARMENUS":
        return (
          <div className="paginaCartao">
            {
              (shelf?.state?.dadosPagina) && (<>
                <MetaTagsUsuarioCartao shelf={shelf} />
                {
                  ((shelf?.state?.usuarioLogadoDonoPagina) && (!shelf.props?.modoDev)) && (<>
                    <Link to="/usuario/painel/personalizar" className="btn btn-roxo btn-editar-cartao"><i class="fad fa-paint-brush-alt"></i></Link>
                  </>)
                }
                <TemaBarMenus dadosPagina={shelf?.state?.dadosPagina} dadosUser={shelf?.state?.dadosUser} usuarioLogadoDonoPagina={shelf?.state?.usuarioLogadoDonoPagina} modoDev={shelf.props?.modoDev} />
              </>)
            }
          </div>
        )

      case "PADRAO":
        return (
          <div className="paginaCartao">
            {
              (shelf?.state?.dadosPagina) && (<>
                <MetaTagsUsuarioCartao shelf={shelf} />
                {
                  ((shelf?.state?.usuarioLogadoDonoPagina) && (!shelf.props?.modoDev)) && (<>
                    <Link to="/usuario/painel/personalizar" className="btn btn-roxo btn-editar-cartao"><i class="fad fa-paint-brush-alt"></i></Link>
                  </>)
                }
                <TemaPadrao dadosPagina={shelf?.state?.dadosPagina} dadosUser={shelf?.state?.dadosUser} usuarioLogadoDonoPagina={shelf?.state?.usuarioLogadoDonoPagina} modoDev={shelf.props?.modoDev} />
              </>)
            }
          </div>
        )

      default:
        return (
          <div className="paginaCartao">
            {
              (shelf?.state?.dadosPagina) && (<>
                <MetaTagsUsuarioCartao shelf={shelf} />
                {
                  ((shelf?.state?.usuarioLogadoDonoPagina) && (!shelf.props?.modoDev)) && (<>
                    <Link to="/usuario/painel/personalizar" className="btn btn-roxo btn-editar-cartao"><i class="fad fa-paint-brush-alt"></i></Link>
                  </>)
                }
                <TemaPadrao dadosPagina={shelf?.state?.dadosPagina} dadosUser={shelf?.state?.dadosUser} usuarioLogadoDonoPagina={shelf?.state?.usuarioLogadoDonoPagina} modoDev={shelf.props?.modoDev} />
              </>)
            }
          </div>
        )
    }
  }

}
