import { Component } from 'react';
import html from './configurar.jsx';
import ApisApp from '../../../../../apis/app.js';
import ApisPaginaApps from '../../../../../apis/paginaApps.js';
import pegarInfosUser from '../../../../../functions/pegarInfosUser.js';
import pegarInfosPagina from '../../../../../functions/pegarInfosPagina.js';


export default class PaginaUsuarioPainelAppConfigurar extends Component {
  constructor(props) {
    super(props);
    this.formEditarConfigAppInstalado = this.formEditarConfigAppInstalado.bind(this, this);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    try {
      let app = (await ApisApp.getSlug(this.props.params.slug)).resposta[0];
      let appInstaladoDados = (await ApisPaginaApps.getAppIdAndPaginaId(1, app?.id, this.state.dadosPagina?.id)).resposta[0];
      await this.setState({
        carregando: false,
        app,
        appInstaladoDados
      });
    } catch (error) {
      console.error(error);
      await this.setState({
        carregando: false,
        erro: String(error)
      });
    }
  }

  async formEditarConfigAppInstalado(shelf, shelfHtml) {
    try {
      shelfHtml.preventDefault();
    } catch (error) { }
    let dadosFormFormado = {configAppJson: []};
    let pegarDadoForm = new FormData(shelfHtml.target);
    dadosFormFormado["configAppJson"][0] = {};
    pegarDadoForm.forEach((valor, chave) => {
      dadosFormFormado["configAppJson"][0][chave] = valor;
    });

    await shelf.setState({
      carregando: true
    })

    try {
        const respostaPut = await ApisPaginaApps.put(dadosFormFormado, shelf?.state?.appInstaladoDados?.id);
        if (respostaPut.status === "OK") {

          window.location.reload();

        } else {
          await shelf.setState({
            carregando: false,
            erro: respostaPut.statusMotivo
          })
        }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      })
    }

  }

  async desinstalarApp(id, shelf) {
    await shelf.setState({
      carregando: true
    })

    try {
        const respostaPut = await ApisPaginaApps.delete(id);
        if (respostaPut.status === "OK") {

          window.location.href = "/usuario/painel/loja";

        } else {
          await shelf.setState({
            carregando: false,
            erro: respostaPut.statusMotivo
          })
        }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      })
    }

  }

  render() {
    return html(this);
  }
}