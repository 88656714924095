import React, { Component } from 'react';
import { MetaTags } from 'react-meta-tags';
import config from '../../config';

export default class MetaTagsPadrao extends Component {

  render() {
    return (
      <MetaTags>
        {
          this.props.nomePagina &&
          <title>{this.props.nomePagina+" | "+config.site.titulo+" - "+config.site.tituloDesc}</title>
        }
        {
          !this.props.nomePagina &&
          <title>{config.site.titulo+" - "+config.site.tituloDesc}</title>
        }
        <meta property="og:title" content={config.site.titulo+" - "+config.site.tituloDesc} />
        <meta name="description" content={config.site.descricao} />
        <meta name="keywords" content={" "+config.site.palavrasChaves} />
        <meta name="theme-color" content="#CCA5F3t" />
        <meta name="msapplication-navbutton-color" content="#CCA5F3t" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#CCA5F3t" />
        <meta name="msapplication-TileColor" content="#CCA5F3t" />
      </MetaTags>
    )
  }
}
