import React, { useEffect, useState } from 'react'
import LayoutCarregando from '../layout/LayoutCarregando';
import LayoutError from '../layout/LayoutError';
import stringToBase64 from '../functions/stringToBase64';

export default function CriadorForm(props) {

    let defaultValue = props.defaultValue;
    let [secoes, setSecoes] = useState([
        {
            nomeSecao: "",
            descSecao: "",
            campos: [
                {
                    tipo: "",
                    nome: "",
                    slug: "",
                    dica: "",
                    opcoes: [
                        {
                            valor: "",
                            acao: "CONTINUAR"
                        }
                    ],
                    obrigatorio: false
                }
            ]
        }
    ]);
    // eslint-disable-next-line
    let [textos, setTextos] = useState({
        "nomeSecao": "Nome da seção",
        "descSecao": "Descrição da seção",
        "nomeCampo": "Nome do campo",
        "addPergunta": "Adicionar campo",
        "addSecao": "Adicionar seção",
        "digiteAqui": "Digite aqui",
        "selecione": "Selecione um tipo",
        "selecioneAdcionar": "Selecione o que essa opção irá acionar",
        "continuarSecao": "Continuar para a próxima seção",
        "concluirForm": "Concluir formulário",
        "irSecao": "Ir para seção ",
        "pergunta": "Pergunta",
        "tipoDeCampo": "Tipo de campo",
        "tiposCampoOpcoes": [
            "Resposta curta",
            "Parágrafo",
            "Múltipla escolha",
            "Caixa de Seleção",
            "Lista suspensa",
            "URL ou Link",
            "Senha",
            "E-mail",
            "Número",
            "Cor",
            "Data",
            "Data Mês",
            "Data e Horário",
            "Horário"
        ],
        "dicaCampo": "Dica do campo",
        "opcaoCampo": "Opção",
        "opcaoAcaoCampo": "Ir para seção com base na resposta",
        "addOpcao": "Adicionar opção",
        "obrigatorio": "Obrigatório"
    });
    let [carregando, setCarregando] = useState(true);
    // eslint-disable-next-line
    let [erro, setErro] = useState(null);

    useEffect(() => {
        (async()=>{
            if (defaultValue?.[0]?.nomeSecao) {
                localStorage.setItem("CriadorForm", await stringToBase64(JSON.stringify(defaultValue)));
                setSecoes(defaultValue);
            }
    
    
            if (!defaultValue?.[0]?.nomeSecao) {
                localStorage.setItem("CriadorForm", "");
            }
            setCarregando(false);
        })()
        // eslint-disable-next-line
    }, [])

    async function onChangeObrigatorio(event, secaoPosicao, campoPosicao) {
        let array = secoes;
        array[secaoPosicao]["campos"][campoPosicao]["obrigatorio"] = event.target.checked;
        await atualizarSecoes(array);
    }

    async function createSlugSecaoFormAdd(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáãäâãèéëêìíïîòóõöôùúüûñç·/_,:;";
        var to = "aaaaaaeeeeiiiiooooouuuunc------";

        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '') // collapse whitespace and replace by -
            .replace(/-+/g, ''); // collapse dashes

        str = str.trim();
        str = str.replace(/\s/g, '');

        return str;
    }

    async function onChangeCampo(event, secaoPosicao, campoPosicao, campo) {
        let array = secoes;
        let valor = event.target.value;
        array[secaoPosicao]["campos"][campoPosicao][campo] = valor;
        switch (campo) {
            case "nome":
                array[secaoPosicao]["campos"][campoPosicao]["slug"] = await createSlugSecaoFormAdd(valor) + campoPosicao + secaoPosicao;
                break;

            case "tipo":
                await setCarregando(true);
                setCarregando(false);
                break;

            default:
                break;
        }
        await atualizarSecoes(array);
    }

    async function onChangeSecoes(event, secaoPosicao, campo) {
        let array = secoes;
        array[secaoPosicao][campo] = event.target.value;
        await atualizarSecoes(array);
    }

    async function onChangeOpcao(event, secaoPosicao, campoPosicao, opcaoPosicao, campo) {
        let array = secoes;
        let valor = event.target.value;
        array[secaoPosicao]["campos"][campoPosicao]["opcoes"][opcaoPosicao][campo] = valor;
        await atualizarSecoes(array);
    }

    async function onClickAddOpcao(secaoPosicao, campoPosicao) {
        setCarregando(true);
        let array = secoes;
        array[secaoPosicao]["campos"][campoPosicao]["opcoes"].push({ posicao: array[secaoPosicao]["campos"][campoPosicao]["opcoes"].length });
        await atualizarSecoes(array);
        setCarregando(false);
    }

    async function onClickAddPergunta(secaoPosicao) {
        setCarregando(true);
        let array = secoes;
        array[secaoPosicao].campos.push({
            posicao: array[secaoPosicao].campos.length,
            opcoes: [{
                acao: "CONTINUAR"
            }]
        });
        await atualizarSecoes(array);
        setCarregando(false);
    }

    async function onClickAddSecao() {
        setCarregando(true);
        let array = secoes;
        array.push({
            posicao: array.length,
            campos: [{
                obrigatorio: false,
                opcoes: [{
                    acao: "CONTINUAR"
                }]
            }]
        });
        await atualizarSecoes(array);
        setCarregando(false);
    }

    async function onClickRemoveOpcao(secaoPosicao, campoPosicao, opcaoPosicao) {
        setCarregando(true);
        let array = secoes;
        delete array[secaoPosicao]["campos"][campoPosicao]["opcoes"][opcaoPosicao];
        array[secaoPosicao]["campos"][campoPosicao]["opcoes"] = array[secaoPosicao]["campos"][campoPosicao]["opcoes"].filter(el => el !== null);
        await atualizarSecoes(array);
        setCarregando(false);
    }

    async function onClickRemoveCampo(secaoPosicao, campoPosicao) {
        setCarregando(true);
        let array = secoes;
        delete array[secaoPosicao]["campos"][campoPosicao];
        array[secaoPosicao]["campos"] = array[secaoPosicao]["campos"].filter(el => el !== null);
        await atualizarSecoes(array);
        setCarregando(false);
    }

    async function onClickRemoveSecao(secaoPosicao) {
        setCarregando(true);
        let array = secoes;
        delete array[secaoPosicao];
        array = array.filter(el => el !== null);
        await atualizarSecoes(array);
        setCarregando(false);
    }

    async function atualizarSecoes(dado) {
        await setSecoes(dado);
        await localStorage.setItem("CriadorForm", await stringToBase64(JSON.stringify(dado)))
        return;
    }

    return (
        <>
            {carregando === true ? (
                <LayoutCarregando />
            ) : (<>
                {erro !== null ? (
                    <LayoutError msg={erro}></LayoutError>
                ) : (<>

                    {secoes.map((secao, iSecao) => (
                        <div className="card p-3 mb-3">
                            <i onClick={() => { onClickRemoveSecao(iSecao) }} className="fas fa-trash-alt text-danger text-right d-flex justify-content-end c-pointer" title={`Remover seção ${(iSecao + 1)}`}></i>
                            <br />
                            <input defaultValue={secao?.nomeSecao} type="text" className="form-control form-cadastro" placeholder={textos?.nomeSecao + " " + (iSecao + 1)} onChange={(e) => { onChangeSecoes(e, iSecao, "nomeSecao") }} />
                            <textarea defaultValue={secao?.descSecao} className="form-control form-cadastro" placeholder={textos?.descSecao + " " + (iSecao + 1)} onChange={(e) => { onChangeSecoes(e, iSecao, "descSecao") }} ></textarea>
                            <br /><br />

                            {
                                secao.campos.map((campo, iCampo) => (
                                    <InputAdd campo={campo} secao={secao} iCampo={iCampo} iSecao={iSecao} textos={textos} />
                                ))
                            }
                            <div className="container-fluid">
                                <button type="button" onClick={() => { onClickAddPergunta(iSecao) }} className="btn btn-painel-roxo float-end">
                                    {textos?.addPergunta}
                                </button>
                                <br /><br /><br /><br />
                            </div>
                        </div>
                    ))}

                    <button type="button" onClick={() => { onClickAddSecao() }} className="btn btn-painel-roxo">
                        {textos?.addSecao}
                    </button>
                </>)
                }
            </>)
            }
        </>
    )

    function InputAdd(props) {

        let [carregando, setCarregando] = useState(true);
        // eslint-disable-next-line
        let [erro, setErro] = useState(null);
        let [textos, setTextos] = useState({});

        useEffect(() => {
            setTextos(props.textos);
            setCarregando(false)
            // eslint-disable-next-line
        }, []);

        return (
            <>
                {carregando === true ? (
                    <LayoutCarregando />
                ) : (<>
                    {erro !== null ? (
                        <LayoutError msg={erro}></LayoutError>
                    ) : (<>
                        <div className="container-fluid">
                            <div className="card bg-color p-3 mb-3">

                                <i onClick={() => { onClickRemoveCampo(props.iSecao, props.iCampo) }} className="fas fa-trash-alt text-danger text-right d-flex justify-content-end c-pointer" title={`Remover campo ${(props.iCampo + 1)}`}></i>

                                <div className="container projetos">

                                    <div className="row">
                                        <div className="col-md-7 col-12">
                                            <label><p>{textos?.nomeCampo + " " + (props.iCampo + 1)}</p></label>
                                            <input defaultValue={props.campo?.nome} type="text" className="form-control form-cadastro mb-3" placeholder={textos?.digiteAqui} onChange={(e) => { onChangeCampo(e, props.iSecao, props.iCampo, "nome") }} />
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <label><p>{textos?.tipoDeCampo + " " + (props.iCampo + 1)}</p></label>
                                            <select defaultValue={props.campo?.tipo} className="form-control form-card mb-3" onChange={(e) => { onChangeCampo(e, props.iSecao, props.iCampo, "tipo") }}>
                                                <option value="" disabled>{textos?.selecione}</option>
                                                <option disabled></option>
                                                <option value="text">{textos?.tiposCampoOpcoes?.[0]}</option>
                                                <option value="textarea">{textos?.tiposCampoOpcoes?.[1]}</option>
                                                <option disabled></option>
                                                <option value="radio">{textos?.tiposCampoOpcoes?.[2]}</option>
                                                <option value="checkbox">{textos?.tiposCampoOpcoes?.[3]}</option>
                                                <option value="select">{textos?.tiposCampoOpcoes?.[4]}</option>
                                                <option disabled></option>
                                                <option value="url">{textos?.tiposCampoOpcoes?.[5]}</option>
                                                <option value="password">{textos?.tiposCampoOpcoes?.[6]}</option>
                                                <option value="email">{textos?.tiposCampoOpcoes?.[7]}</option>
                                                <option value="number">{textos?.tiposCampoOpcoes?.[8]}</option>
                                                <option value="color">{textos?.tiposCampoOpcoes?.[9]}</option>
                                                <option disabled></option>
                                                <option value="date">{textos?.tiposCampoOpcoes?.[10]}</option>
                                                <option value="month">{textos?.tiposCampoOpcoes?.[11]}</option>
                                                <option value="datetime-local">{textos?.tiposCampoOpcoes?.[12]}</option>
                                                <option value="time">{textos?.tiposCampoOpcoes?.[13]}</option>
                                            </select>
                                        </div>
                                        <div className="col-md-10 col-12">
                                            <label><p>{textos?.dicaCampo + " " + (props.iCampo + 1)}</p></label>
                                            <input defaultValue={props.campo?.dica} type="text" className="form-control form-cadastro mb-3" placeholder={textos?.digiteAqui} onChange={(e) => { onChangeCampo(e, props.iSecao, props.iCampo, "dica") }} />
                                        </div>


                                        <div className="col-md-2 col-12 d-flex align-items-center">
                                            <div className="obrigatorio">
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" type="checkbox" id={`obrigatorio-${props.iCampo}-${props.iSecao}`} defaultChecked={props.campo?.obrigatorio} value="true"  onChange={(e) => { onChangeObrigatorio(e, props.iSecao, props.iCampo) }} />
                                                    <label className="form-check-label" for={`obrigatorio-${props.iCampo}-${props.iSecao}`}>{textos?.obrigatorio}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    {
                                        ((props.campo?.tipo === "radio") || (props.campo?.tipo === "checkbox") || (props.campo?.tipo === "select")) && (
                                            <div className="opcoes">
                                                {
                                                    props.campo?.opcoes.map((opcao, iOpcoes) => (<>
                                                        <div className="card p-3 mt-3">
                                                            <i onClick={() => { onClickRemoveOpcao(props.iSecao, props.iCampo, iOpcoes) }} className="fas fa-trash-alt text-danger d-flex justify-content-end c-pointer" title={`Remover opção ${(iOpcoes + 1)}`}></i>
                                                            <div className="row">
                                                                <div className="col-md col">
                                                                    <label><p>{textos?.opcaoCampo + " " + (iOpcoes + 1)}</p></label>
                                                                    <input defaultValue={opcao?.valor} type="text" className="form-control form-cadastro mb-3" placeholder={textos?.digiteAqui} onChange={(e) => { onChangeOpcao(e, props.iSecao, props.iCampo, iOpcoes, "valor") }} />
                                                                </div>
                                                                {
                                                                    ((props.campo?.tipo === "radio") || (props.campo?.tipo === "select")) && (
                                                                        <div className="col-md col">
                                                                            <label><p>{textos?.opcaoAcaoCampo + " " + textos?.opcaoCampo + " " + (iOpcoes + 1)}</p></label>
                                                                            <select defaultValue={opcao?.acao} className="form-control form-card mb-3" onChange={(e) => { onChangeOpcao(e, props.iSecao, props.iCampo, iOpcoes, "acao") }}>
                                                                                <option value="" disabled>{textos?.selecioneAdcionar}</option>
                                                                                <option value="CONTINUAR" selected>{textos?.continuarSecao}</option>
                                                                                <option value="CONCLUIR">{textos?.concluirForm}</option>
                                                                                <option disabled></option>
                                                                                {
                                                                                    secoes.map((secao, iSecao) => (
                                                                                        <option value={iSecao}>{textos?.irSecao}{(iSecao + 1)} ({secao?.nomeSecao})</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </>))
                                                }
                                                <div className="row">
                                                    <div className="col-12">
                                                        <center>
                                                            <br />
                                                            <button type="button" onClick={() => { onClickAddOpcao(props.iSecao, props.iCampo,) }} className="btn btn-painel-roxo btn-block" style={{ margin: '0px' }}>
                                                                {textos?.addOpcao}
                                                            </button>
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </>)
                    }
                </>)
                }
            </>
        )
    }

}