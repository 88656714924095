import { Component } from 'react';
import html from './criar.jsx';
import pegarInfosUser from '../../../../../functions/pegarInfosUser.js';
import pegarDadosFormCriarPagina from '../../../../../functions/pegarDadosFormCriarPagina.js';

export default class PaginaUsuarioPainelPaginasCriar extends Component {
  constructor(props) {
    super(props);
    this.pegarDadosFormCriarPagina = pegarDadosFormCriarPagina.bind(this, this);
    this.state = {
      erro: null,
      carregando: true,
      ocultarWhats: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await this.setState({
      carregando: false,
      pMsg: this.props.location.query.msg
    });
  }


  render() {
    return html(this);
  }
}