import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import { Link } from 'react-router';

export default function html(shelf) {

  let paginas = [
    {
      nome: "Apps",
      link: "/dev/painel/apps",
      icon: "fad fa-th-large"
    },
    {
      nome: "Temas",
      link: "/dev/painel/temas",
      icon: "fad fa-brush"
    }
  ]

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaDevInicio">
        <div className="container">

          <br />
          <h3 className="text-center"> Olá, dev!</h3>
          <br />

          <div className="menuDev">
            {
              paginas.map((pagina) => (
                <Link className="quadradoMenuDev bg-roxo text-white" to={pagina.link}>
                  <i className={pagina.icon}></i>
                  <h5><b>{pagina.nome}</b></h5>
                </Link>
              ))
            }
          </div>


        </div >
      </div >
    );
  }
}
