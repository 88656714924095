import ApisPaginas from "../apis/paginas";
import stringToBase64 from "./stringToBase64";

export default async function pegarInfosPagina(shelf) {
  return new Promise(async (resolve, reject) => {
    try {
      let paginaId = localStorage.getItem("paginaId");
      if (!paginaId) {
        try {
          paginaId = shelf.state.dadosUser.paginas[0].id;
          localStorage.setItem("paginaId", String(paginaId));
        } catch (error) {
          window.location.href = "/usuario/painel/paginas";
          return;
        }
      }
      const respostadadosPagina = await ApisPaginas.obterPorId(paginaId);
      if (respostadadosPagina.resposta) {
        let dadosPagina = respostadadosPagina.resposta[0];
        try {
          dadosPagina.link_whatsapp = dadosPagina.link_whatsapp.replace("https://api.whatsapp.com/send?phone=55", "");
        } catch (err) { }

        try {
          let modoDevPre = JSON.parse(this.props?.modoDev?.state?.predefinicoes);
          if (modoDevPre) {
            if(modoDevPre?.cor){dadosPagina.cor = modoDevPre?.cor;}
            if(modoDevPre?.modelo){dadosPagina.modelo = modoDevPre?.modelo;}
            if(modoDevPre?.linksPadrao?.[0]?.titulo){dadosPagina.links = modoDevPre?.linksPadrao;}
          }
        } catch (error) {}

        await shelf.setState({dadosPagina});
        localStorage.setItem("dadosPagina", await stringToBase64(JSON.stringify(dadosPagina)));
        resolve(dadosPagina);
      } else {
        await shelf.setState({
          carregando: false,
          erro: "Página não encontrada!"
        })
        window.location.href = "/usuario/painel/paginas";
        localStorage.removeItem("dadosPagina");
        localStorage.removeItem("paginaId");
        reject(respostadadosPagina.statusMotivo);
        console.log("🚀 ~ file: inicio.js:77 ~ PaginaUsuarioPainelInicio ~ returnnewPromise ~ respostadadosPagina.statusMotivo:", respostadadosPagina.statusMotivo)
      }
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      });
      window.location.href = "/logar";
      localStorage.removeItem("dadosPagina");
      reject(error);
    }
  });
}