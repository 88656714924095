import { Component } from 'react';
import html from './cartao.jsx';
import pegarInfosUser from '../../../functions/pegarInfosUser.js';
import pegarInfosPagina from '../../../functions/pegarInfosPagina.js';
import pegarInfosPaginaPorSlug from '../../../functions/pegarInfosPaginaPorSlug.js';

export default class PaginaUsuarioCartao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.removeItem("dadosPagina");
    if (String(this.props.params.slug) === "meu-icartao") {
      await pegarInfosUser(this);
      await pegarInfosPagina(this);
      this.setState({ usuarioLogadoDonoPagina: true, carregando: false });
    } else {
      await pegarInfosUser(this, false);
      await pegarInfosPaginaPorSlug(this);
      let usuarioLogadoDonoPagina = this?.state?.dadosUser?.paginas?.find((pg) => pg.id === this?.state?.dadosPagina?.id)?.id !== undefined;
      await this.setState({ usuarioLogadoDonoPagina, carregando: false })
    }
    console.log(this?.state?.usuarioLogadoDonoPagina)
  }

  async componentDidUpdate(antigaProps) {
    console.log(this.props?.modoDev);
    if (JSON.stringify(antigaProps?.modoDev) !== JSON.stringify(this.props?.modoDev)) {
      try {
        let dadosPagina = this.state?.dadosPagina;
        let modoDevPre = JSON.parse(this.props?.modoDev?.state?.predefinicoes);
        if (modoDevPre) {
          if (modoDevPre?.cor) { dadosPagina.cor = modoDevPre?.cor; }
          if (modoDevPre?.modelo) { dadosPagina.modelo = modoDevPre?.modelo; }
          if (modoDevPre?.linksPadrao?.[0]?.titulo) { dadosPagina.links = modoDevPre?.linksPadrao; }

          this.setState({ dadosPagina });
        }
      } catch (error) { }
    }
  }

  converterNomeCorHex(cor) {
    switch (String(cor).toLowerCase()) {
      case "verde":
        return "#9fe69f";
      case "vermelho":
        return "#f08080";
      case "amarelo":
        return "#ecec8a";
      case "azul":
        return "#6eabd1";

      default:
        return "#ecd2fa";
    }
  }

  render() {
    return html(this);
  }
}