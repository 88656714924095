export default class ApisPaginaApps {

    static post(dadosForm) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dadosForm);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/post.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static put(dadosForm, id) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(dadosForm);

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/put.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&id=" + id, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static delete(id) {
        return new Promise((resposta, error) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/delete.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&id=" + id, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static getAll(limit) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit=" + limit, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static getPaginaId(limit, paginaId) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit="+limit+"&paginaId=" + paginaId, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static getAppId(limit, appId) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit="+limit+"&appId=" + appId, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

    static getAppIdAndPaginaId(limit, appId, paginaId) {
        return new Promise((resposta, error) => {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_PHP_BASE_URL + "user/paginas/apps/instalados/get.php?token=" + process.env.REACT_APP_API_PHP_PRIVADA_TOKEN_AMBIENTE + "&limit="+limit+"&appId=" + appId + "&paginaId="+ paginaId, requestOptions)
                .then(response => resposta(response.json()))
                .catch(err => error(err));

        });
    }

}
