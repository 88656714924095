import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import { Link } from '@version/react-router-v3';
import config from '../../../../../config';
import * as Funcoes from '../../../../../funcoes';
import PaginaUsuarioCartao from '../../cartao/cartao';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaUser">
        {
          (shelf.state.dadosPagina?.tema?.codigoCss) && (
            <div dangerouslySetInnerHTML={{
              __html: `
            <!-- Style Customizada do tema -->
              <style>
                ${shelf.state.dadosPagina?.tema?.codigoCss ? Funcoes.replaceAll(shelf.state.dadosPagina?.tema?.codigoCss, "</", "") : ""}
              </style>
          `}} />
          )
        }
        <div className="container">

          {
            shelf.state.pMsg &&
            <div>
              <br /><br />
              <div className="alert alert-info" role="alert">
                <span>{shelf.state.pMsg}</span>
              </div>
            </div>
          }

          <br />

          <div className="row no-gutters">
            <div className="col-sm-8 coluna-form">

              {/* 
              <div className="imagem-perfil">
                <img src={config.arquivos.baseUrl + shelf.state.dadosPagina.foto} alt="Foto do usuario"></img>
              </div>
              <br />

              <center>
                <label for="arquivo" className="custom-file-upload">
                  Mudar imagem
                </label>
                <input id="arquivo" name="arquivo" type="file" accept="image/*" max="1" onChange={shelf.pegarFotoFazerUpload} />
              </center> */}

              <br />

              <div className="dados-form" >
                <label htmlFor="arquivo"><p>Foto de perfil:</p></label>
                <input type="file" accept="image/*" max="1" className="form-control form-card" name="arquivo" id="arquivo" onChange={shelf.pegarFotoFazerUpload} />
                <small className="text-warning">Só escolha um arquivo nesse campo caso queira alterar a foto de perfil.</small>
                <br /><br />
              </div>

              <form className="dados-form" id="formDadosEditarUser" onSubmit={shelf.pegarDadosFormAtualizarPagina}>
                <div className="form-group" scrolling="yes">

                  <div className="form-group" scrolling="yes">
                    <label htmlFor="slug"><p>Link personalizado:</p></label>
                    <div className="row">
                      <div className="col-6">
                        <input defaultValue="icartao.me/@" type="text" className="form-control form-card" disabled />
                      </div>
                      <div className="col-6">
                        <input defaultValue={shelf.state.dadosPagina.slug} type="text" className="form-control form-card" name="slug" id="slug" required />
                      </div>
                    </div>
                  </div>
                  <br />

                  <label htmlFor="nome"><p>Título:</p></label>
                  <input defaultValue={shelf.state.dadosPagina.nome} type="text" className="form-control form-card" name="nome" id="nome" placeholder="Título" required />
                  <br />

                  <label htmlFor="sobre"><p>Sobre:</p></label>
                  <textarea className="form-control form-card" name="sobre" id="sobre" defaultValue={shelf.state.dadosPagina.sobre} placeholder="Escreva um pouco sobre você, para ficar no topo da sua pagina de contato."></textarea>
                  <br />
                  <br />

                  <div className={`escolher-cor-tema ${shelf.state.dadosPagina?.tema?.nome && `ocultar`}`}>
                    <p>Escolha a cor do seu cartão:</p>

                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'padrao' } })} type="button" className="btn btn-redondo-cor btn-light btn-roxo" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'verde' } })} type="button" className="btn btn-redondo-cor btn-success btn-verde" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'azul' } })} type="button" className="btn btn-redondo-cor btn-primary btn-blue" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'vermelho' } })} type="button" className="btn btn-redondo-cor btn-danger btn-vermelho" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'amarelo' } })} type="button" className="btn btn-redondo-cor btn-warning btn-amarelo" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'rosa' } })} type="button" className="btn btn-redondo-cor btn-rosa" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'preto' } })} type="button" className="btn btn-redondo-cor btn-preto" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'branco' } })} type="button" className="btn btn-redondo-cor btn-branco" corBanco={shelf.state.dadosPagina.cor}></button>
                    <button onClick={() => shelf.setState({ dadosPagina: { ...shelf.state.dadosPagina, cor: 'preto-branco' } })} type="button" className="btn btn-redondo-cor btn-preto-branco" corBanco={shelf.state.dadosPagina.cor}></button>
                    <input type="hidden" name="cor" defaultValue={shelf.state.dadosPagina.cor} />

                    <br />
                    <br />
                  </div>

                  <p>Selecione um tema:</p>


                  <div className="row galeria-temas">
                    {
                      (shelf.state.dadosPagina?.tema?.nome) ? (<>

                        <div className="col-md-4 col-6 mb-3">
                          <div className={`card card-${shelf.state.dadosPagina?.tema?.nome} selecionado`}>
                            {/* eslint-disable-next-line */}
                            <a>
                              {
                                (shelf.state.dadosPagina?.tema?.prints?.[0]) && (
                                  <img src={config.arquivos.baseUrl + shelf.state.dadosPagina?.tema?.prints?.[0]} className="card-img-top" alt="Ilustração de previa da pagina do cartão." />
                                )
                              }
                              <div className="card-body">
                                <h5 className="card-title">{shelf.state.dadosPagina?.tema?.nome}</h5>
                              </div>
                            </a>
                          </div>
                        </div>

                        <div className="col-md-4 col-6 mb-3">
                          <div className="card card-VOLTAR" corBanco={`branco`}>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => { shelf.usarTemasPadroes(shelf) }}>
                              <img src="https://i.imgur.com/ZNqsWLD.png" className="card-img-top" alt="Ilustração de previa da pagina do cartão colorida." />
                              <div className="card-body">
                                <h5 className="card-title">Temas padrões</h5>
                              </div>
                            </a>
                          </div>
                        </div>

                      </>) : (<>
                        <input type="hidden" name="modelo" defaultValue={shelf.state.dadosPagina.modelo} />

                        <div className="col-md-4 col-6 mb-3">
                          <div className="card card-BARMENUS" corBanco={shelf.state.dadosPagina.cor}>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => { shelf.selecionarModelo("BARMENUS") }}>
                              <img src="https://i.imgur.com/ZNqsWLD.png" className="card-img-top" alt="Ilustração de previa da pagina do cartão colorida." />
                              <div className="card-body">
                                <h5 className="card-title">Barras</h5>
                              </div>
                            </a>
                          </div>
                        </div>

                        <div className="col-md-4 col-6 mb-3">
                          <div className="card card-QUADRADOS" corBanco={shelf.state.dadosPagina.cor}>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => { shelf.selecionarModelo("QUADRADOS") }}>
                              <img src="https://i.imgur.com/Q414Qtp.png" className="card-img-top" alt="Ilustração de previa da pagina do cartão colorida." />
                              <div className="card-body">
                                <h5 className="card-title">Quadrados</h5>
                              </div>
                            </a>
                          </div>
                        </div>

                        <div className="col-md-4 col-6 mb-3">
                          <div className="card card-PADRAO" corBanco={shelf.state.dadosPagina.cor}>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => { shelf.selecionarModelo("PADRAO") }}>
                              <img src="https://i.imgur.com/GBKn1zR.png" className="card-img-top" alt="Ilustração de previa da pagina do cartão colorida." />
                              <div className="card-body">
                                <h5 className="card-title">Círculos</h5>
                              </div>
                            </a>
                          </div>
                        </div>
                      </>)
                    }


                  </div>

                  <center>
                    <Link to={"/usuario/painel/loja#temas"} className="btn btn-link">Ver mais temas </Link>
                  </center>

                  <br />
                  <br />

                  <center><button type="submit" className="btn btn-painel-roxo btn-lg"><i class="fad fa-save"></i> Salvar Modificações</button></center>

                </div>
              </form>

            </div>

            <div className="col-sm-4 remove-mobile" scrolling="no">
              <button className="btn-link-copy" onClick={() => navigator.clipboard.writeText(`https://icartao.me/@${shelf.state.dadosPagina.slug}`)}>
                <i className="far fa-clone"></i>
              </button>
              {/* eslint-disable-next-line */}
              <a className="a-link" target="_blank" href={process.env.PUBLIC_URL + "/p/" + shelf.state.dadosPagina.slug}>icartao.me{"/@" + shelf.state.dadosPagina.slug}</a>
              <br /><br />

              <div className="imagem-preview preview-pagina">
                <PaginaUsuarioCartao
                  modoDev={{
                    state: {
                      predefinicoes: JSON.stringify(shelf?.state?.dadosPagina)|| {}
                    }
                  }}
                  params={{
                    slug: "meu-icartao"
                  }}
                />
              </div>
              <br />
            </div>

          </div>
          <br />
        </div >






      </div >





    );
  }
}
