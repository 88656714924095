import React from 'react';
import LayoutError from '../../../../../layout/LayoutError';
import LayoutCarregando from '../../../../../layout/LayoutCarregando';
import CriadorForm from '../../../../../componentes/criadorForm';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaDevApps">
        <div className="container">
          <br /><br />

          <form className="dados-form" id="formCriarApp" onSubmit={shelf.formCriarApp} >
            <label htmlFor="nome"><p>Nome:</p></label>
            <input type="text" className="form-control form-card" name="nome" id="nome" placeholder="Nome " required />
            <br />


            <label htmlFor="iconeFile"><p>Icone:</p></label>
            <input type="file" accept="image/png" max="1" className="form-control form-card" name="iconeFile" id="iconeFile" />
            <small className="text-info">Tamanho da imagem de no maximo 200px por 200px, deve ser .png, cortaremos um pouco as bordas.</small>
            <br /><br />

            <label htmlFor="descricao"><p>Descrição :</p></label>
            <textarea className="form-control form-card" name="descricao" id="descricao" placeholder="Descrição breve do seu aplicativo." required></textarea>
            <br />

            <label htmlFor="preco"><p>Preço:</p></label>
            <input type="number" min="0" max="20" className="form-control form-card" name="preco" id="preco" placeholder="Preço " required />
            <br />

            <div className="mt-5 mb-5">
              <label htmlFor="descricao"><p><b>Criador de formulario:</b></p></label>
              <CriadorForm defaultValue={[]} />
            </div>

            <br />
            <br />

            <center><button type="submit" className="btn btn-painel-roxo btn-lg"><i class="fad fa-save"></i> Criar app</button></center>


            <br />
            <br />
            
          </form>

        </div >
      </div >
    );
  }
}
