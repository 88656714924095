import { Component } from 'react';
import ApisStripeCheckout from '../../apis/stripeCheckout.js';
import PaginaUsuarioPainelPersonalizar from '../../paginas/usuario/painel/personalizar/personalizar.js';
import html from './cardTema.jsx';
import ApisPaginas from '../../apis/paginas.js';


export default class ComponenteCardTema extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    this.setState({
      carregando: false,
      erro: null
    });
  }

  async onClickComprarTema(shelf, dadosUser, tema) {
    shelf.setState({ carregando: true, erro: null });
    try {

      let sessaoCheckout = await ApisStripeCheckout.criar({
        query: {
          codeRef: `tema-${tema?.id}`
        },
        data: {
          user: {
            id: dadosUser?.id,
            nome: dadosUser?.nome,
            email: dadosUser?.email
          }
        }
      });

      if(sessaoCheckout?.status === "OK"){
        window.open(sessaoCheckout?.resposta?.url, '_blank');
        await shelf.setState({ carregando: false });
      }else{
        throw new Error(sessaoCheckout?.statusMotivo);
      }
    } catch (error) {
      shelf.setState({ carregando: false, erro: String(error) });
    }

  }

  async onClickUsarTema(shelf, userToken, tema) {
    let urlRedirect = "/";
    let confirmacao = window.confirm("Deseja mesmo aplicar esse tema?");

    if (confirmacao) {
      await shelf.setState({
        carregando: true
      })
      try {

        let dadosEdit = {};
        let confirmacaoResetLinks = window.confirm(`Deseja mesmo resetar os links do seu iCartão e usar os padrões desse tema?`);
        if (confirmacaoResetLinks) {
          urlRedirect = "/usuario/painel/links";
          dadosEdit = {
            temaId: tema?.id,
            cor: tema?.predefinicoes?.cor,
            modelo: tema?.predefinicoes?.modelo,
            links: tema?.predefinicoes?.linksPadrao,
          };
        } else {
          urlRedirect = "/usuario/painel/personalizar";
          dadosEdit = {
            temaId: tema?.id,
            cor: tema?.predefinicoes?.cor,
            modelo: tema?.predefinicoes?.modelo,
          };
        }


        const respostaApiUser = await ApisPaginas.editar(dadosEdit, shelf?.props?.dadosPagina?.id);
        if (respostaApiUser?.status === "OK") {
          alert(`Tema ${tema?.nome} aplicado com sucesso!`);
          window.location.href = urlRedirect;
        } else {
          await shelf.setState({
            carregando: false,
            erro: "Não conseguimos fazer a instalação desse tema."
          })
        }
      } catch (error) {
        await shelf.setState({
          carregando: false,
          erro: error
        });
      }
    }

  }

  async onClickDesintalarTema(shelf, userToken, tema) {
    await shelf.setState({
      carregando: true
    })
    try {
      await PaginaUsuarioPainelPersonalizar.prototype.usarTemasPadroes(shelf);
      window.location.href = "/usuario/painel/personalizar";
    } catch (error) {
      await shelf.setState({
        carregando: false,
        erro: error
      });
    }
  }

  render() {
    return html(this);
  }
}