import ApisPaginas from "../apis/paginas";
import stringToBase64 from "./stringToBase64";

export default async function pegarInfosPaginaPorSlug(shelf) {
  return new Promise(async (resolve, reject) => {
    try {
      const respostadadosPagina = await ApisPaginas.obterPorSlug(shelf.props.params.slug);
      if (respostadadosPagina.resposta) {
        await shelf.setState({ dadosPagina: respostadadosPagina.resposta[0], carregando: false });
        localStorage.setItem("dadosPagina", await stringToBase64(JSON.stringify(respostadadosPagina.resposta[0])));
        resolve(respostadadosPagina.resposta[0]);
      } else {
        await shelf.setState({ erro: "Usuario não encontrado, redirecionando para pagina 404..." })
        // window.location.href = "/404";
        localStorage.removeItem("dadosPagina");
        reject(respostadadosPagina.statusMotivo);
      }
    } catch (error) {
      await shelf.setState({  erro: error });
      // window.location.href = "/404";
      localStorage.removeItem("dadosPagina");
      reject(error);
    }
  });
}