import { Component } from 'react';
import html from './inicio.jsx';
import $ from "jquery";
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';
import pegarInfosPagina from '../../../../functions/pegarInfosPagina.js';

export default class PaginaUsuarioPainelInicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true,
      ocultarWhats: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    await this.setState({
      carregando: false,
      pMsg: this.props.location.query.msg
    });
    const shelf = this;
    $(document).ready(async function () {
      if(shelf.state?.dadosPagina?.links?.[1]?.link){
        $("body").append(`<script>Onboarding.init({ servicoChave: "dc011fc6d93818021cd5c00b34c669af", guiaCodigo: "pagina_inicio", checkMensagens: true, assinanteCodigo: "${shelf?.state?.dadosPagina?.id}", assinanteInfo: "@${shelf?.state?.dadosPagina?.slug} (${shelf?.state?.dadosUser?.nome})", dominio: "icartao.me" });</script>`);
      }else{
        $("body").append(`<script>Onboarding.init({ servicoChave: "dc011fc6d93818021cd5c00b34c669af", guiaCodigo: "pagina_inicio", checkMensagens: false, assinanteCodigo: "${shelf?.state?.dadosPagina?.id}", assinanteInfo: "@${shelf?.state?.dadosPagina?.slug} (${shelf?.state?.dadosUser?.nome})", dominio: "icartao.me" });</script>`);
      }
    });
  }

  render() {
    return html(this);
  }
}