import React, { Component } from 'react';
import { Link } from 'react-router';
import $ from 'jquery';

export default class LayoutNavegacaoPc extends Component {

    fecharCollapse() {
        $(".navbar-collapse").collapse('hide');
    }

    render() {
        let paginas = [
            {
                nome: "Início",
                link: "https://icartao.me/",
                icon: "fad fa-home",
                userLogado: false
            },
            {
                nome: "Logar",
                link: "/logar",
                icon: "fad fa-sign-in-alt",
                userLogado: false
            },
            {
                nome: "Crie seu cartão",
                link: "/registre-se",
                icon: "fad fa-user-plus",
                userLogado: false
            },
            {
                nome: "Esqueci minha senha",
                link: "/usuario/senha/recuperar",
                icon: "fad fa-key",
                userLogado: false
            },
            {
                nome: "Início",
                link: "/usuario/painel/inicio",
                icon: "fad fa-home",
                userLogado: true
            },
            {
                nome: "Links",
                link: "/usuario/painel/links",
                icon: "fad fa-link",
                userLogado: true
            },
            {
                nome: "Personalizar",
                link: "/usuario/painel/personalizar",
                icon: "fad fa-paint-brush-alt",
                userLogado: true
            },
            {
                nome: "Loja",
                link: "/usuario/painel/loja",
                icon: "fad fa-th-large",
                userLogado: true
            },
            {
                nome: "Vídeos tutoriais",
                link: "https://www.youtube.com/@icartao",
                icon: "fab fa-youtube",
                userLogado: true,
                target: "_blank"
            },
            {
                nome: "Ver meu iCartão",
                link: "/usuario/painel/ver-meu-icartao",
                icon: "fad fa-eye",
                userLogado: true
            },
            {
                nome: "Comunidade",
                link: "https://github.com/iCartao/Comunidade/discussions",
                icon: "fab fa-github",
                userLogado: true,
                target: "_blank"
            },
            {
                nome: "Sair",
                link: "/usuario/painel/sair",
                icon: "fad fa-sign-out",
                userLogado: true
            }
        ]

        return (
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    {/* PC */}
                    <a className="navbar-brand remove-mobile" href="/"><img src={process.env.PUBLIC_URL + "/img/Logo/Icartão_Logo_tamanho_normal_Gradiente_Roxo.png"} alt="Logo" style={{ width: "50px" }} /></a>

                    {/* Mobile */}
                    <button className="navbar-icon-voltar remove-pc" onClick={() => { window.history.go(-1); return false; }}><i className="fal fa-chevron-left"></i></button>
                    <div className="remove-pc d-flex justify-content-center text-center">
                        <h1>{this.props.nomePagina}</h1>
                    </div>

                    {/* Mobile e Pc */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fal fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto"></ul>
                        <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-end">

                            {
                                paginas.map((pagina, index) => (<>
                                    {this.props.userLogado === pagina.userLogado && (
                                        <li className="nav-item" key={`pc${index}`}>
                                            {
                                                (String(pagina?.link).includes("//")) ? (
                                                    <a href={pagina.link} target={pagina.target || "_self"} className="nav-link" aria-current="page"><i className={pagina.icon}></i> {pagina.nome}</a>
                                                ) : (<>
                                                    <Link to={pagina.link} onClick={() => { this.fecharCollapse() }} className="nav-link" aria-current="page"><i className={pagina.icon}></i> {pagina.nome}</Link>
                                                </>)
                                            }
                                        </li>
                                    )}
                                </>
                                ))
                            }

                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

