import React from 'react';
import LayoutError from '../../layout/LayoutError';
import LayoutCarregando from '../../layout/LayoutCarregando';
import config from '../../../config';
import { Link } from 'react-router';
import ComponenteCardApp from './cardApp';

export default function html(shelf) {


  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="ComponenteCardApp" key={`cardApp${shelf?.props?.key}`}>

        <div className="card card-loja mb-3 p-1">
          <div className="row g-0">
            <div className="col-2 col-md-1 d-grid align-content-center">
              <img src={config.arquivos.baseUrl + shelf?.props?.app?.icone} className="img-fluid rounded-start" alt="Icone do app." />
            </div>
            <div className="col-6 col-md-10 ps-2">
              <h5 className="card-title limite-1linha-ch-100p" title={shelf?.props?.app?.nome}>{shelf?.props?.app?.nome}</h5>
              <p className="card-text limite-1linha-ch-100p" title={shelf?.props?.app?.descricao}><small className="text-muted">{shelf?.props?.app?.descricao}</small></p>
            </div>
            <div className="col-4 col-md-1 d-flex align-items-center justify-content-end">
              {
                shelf?.props?.app?.instalado ? (<>
                  <Link disabled={shelf?.props?.disabled} to={`/usuario/painel/app/configurar/${shelf?.props?.app?.slug}`} className="btn btn-outline-secondary text-start">
                    Configurar
                  </Link>
                </>) : (<>
                  {
                    (shelf?.props?.dadosClienteStripe?.assinaturasAtivas?.findIndex(assinatura => assinatura?.plan?.produtoDetalhes?.metadata?.codeRef === `app-${shelf?.props?.app?.id}`) !== -1) ? (<>
                      <button disabled={shelf?.props?.disabled} className="btn btn-outline-secondary text-start" onClick={() => { shelf.onClickInstalar(shelf, shelf.props.dadosPagina?.id, shelf?.props?.app) }}>
                        <span className="text-info">Comprado</span>
                        <br />
                        <span>Instalar</span>
                      </button>
                    </>) : (<>
                      {Number(shelf?.props?.app?.preco) === 0 ? (<>
                        <button disabled={shelf?.props?.disabled} className="btn btn-outline-secondary text-start" onClick={() => { shelf.onClickInstalar(shelf, shelf.props.dadosPagina?.id, shelf?.props?.app) }}>
                          <span className="text-success">Grátis</span>
                          <br />
                          <span>Instalar</span>
                        </button>
                      </>) : (<>
                        <button disabled={shelf?.props?.disabled} className="btn btn-outline-secondary text-start" onClick={() => { ComponenteCardApp.onClickComprarApp(shelf, shelf?.props?.app) }}>
                          <span className="text-info">{Number(shelf?.props?.app?.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                          <br />
                          <span>Comprar</span>
                        </button>
                      </>)}
                    </>)}
                </>)
              }
            </div>
          </div>
        </div>

      </div >
    );
  }
}
