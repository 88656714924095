import React, { Component } from 'react';
import * as Funcoes from '../../funcoes';
import MetaTagsPadrao from './metaTagsPadrao';
import LayoutNavegacaoCelular from './navegacaoCelular';
import LayoutNavegacaoPc from './navegacaoPc';
import $ from 'jquery';

export default class LayoutModeloNavegacao extends Component {

    verificarAtivarPaginasNavegacao() {
        let props = this.props;
        let pathPagina;
        try {
            pathPagina = props.routes[1].path;
        } catch (error) {
            try {
                pathPagina = props.routes[0].path;
            } catch (error) {
                pathPagina = "Pagina sem path";
            }
        }
        $(document).ready(function () {
            $(`.navMobileLink.active`).removeClass("active");
            $(`.nav-link.active`).removeClass("active");
            $(`.nav-link[href="${pathPagina}"]`).addClass("active");
            $(`.navMobileLink[href="${pathPagina}"]`).addClass("active");
        });
    }

    componentDidUpdate() {
        this.verificarAtivarPaginasNavegacao();
    }

    render() {
        let props = this.props;
        let nomePagina, userLogado;
        try {
            nomePagina = props.routes[1].nomePagina;
        } catch (error) {
            try {
                nomePagina = props.routes[0].nomePagina;
            } catch (error) {
                nomePagina = "Pagina sem nome";
            }
        }
        Funcoes.verificarUserLogadoOffline(() => {
            userLogado = true;
        }, () => {
            userLogado = false;
        });
        this.verificarAtivarPaginasNavegacao();

        if ((userLogado === true) || (userLogado === false)) {
            return (
                <div className={"LayoutModeloNavegacao mobile-"+window.navigator?.userAgentData?.mobile}>
                    <MetaTagsPadrao nomePagina={nomePagina} />
                    <LayoutNavegacaoPc nomePagina={nomePagina} userLogado={userLogado} />
                    <div className="conteudoPagina pb-5">
                        {this.props.children}
                    </div>
                    <LayoutNavegacaoCelular userLogado={userLogado} />
                    <div className="mb-3" />
                </div>
            )
        }
    }
}

