export default function getYouTubeVideoId(url) {
  // Expressão regular para o formato curto e longo do YouTube
  const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regExp);

  if (match && match[1]) {
      return match[1];
  }

  // Caso a URL não seja válida, você pode retornar null ou uma mensagem de erro.
  return null;
}