import React from 'react';
import LayoutError from '../../../../layout/LayoutError';
import LayoutCarregando from '../../../../layout/LayoutCarregando';
import { Link } from 'react-router';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className="paginaDevTemas">
        <div className="container">

          <div className="d-flex justify-content-end align-items-end mt-4 mb-2">
            <Link to={"/dev/painel/temas/criar"} className="btn btn-painel-roxo">Criar novo tema</Link>
          </div>

          {
            String(shelf.state?.temas) !== "undefined" ? (<>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Status</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    shelf.state.temas.map((tema) => (
                      <tr>
                        <th scope="row">{tema?.id}</th>
                        <td>{tema?.nome}</td>
                        <td>{tema?.status}</td>
                        <td>
                          {Number(tema?.preco) === 0 ? (
                            <span className="text-success">Grátis</span>
                          ) : (
                            <span className="text-info">{Number(tema?.preco).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                          )}
                        </td>
                        <td>
                          <Link to={"/dev/painel/temas/editar/" + tema?.slug}>Editar</Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>

              </table>

            </>) : (<>
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <div>
                  <h1 className="text-center">Sem temas</h1>
                  <p className="text-center">Ainda não temos temas publicados.</p>
                </div>
              </div>
            </>)
          }

        </div >
      </div >
    );
  }
}
