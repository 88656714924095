import React from 'react';
import { Component } from 'react';
import pegarInfosUser from '../../../../functions/pegarInfosUser';
import pegarInfosPagina from '../../../../functions/pegarInfosPagina';

export default class PaginaUsuarioPainelVerMeuCartao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await pegarInfosPagina(this);
    let url = process.env.PUBLIC_URL + "/@" + this.state.dadosPagina.slug;
    this.setState({ url });
    try {
      var win = window.open(url, '_blank');
      win.focus();
      window.history.go(-1);
    } catch (error) { }
  }

  render() {
    return (<>
      <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
        <center>
          <p><b>Caso você não seja direcionado automaticamente, <a href={this.state.url} target="_blank" without rel="noreferrer">clique aqui</a></b></p>
        </center>
      </div>
    </>);
  }
}