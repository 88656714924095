import { Component } from 'react';
import html from './sucesso.jsx';
import ApisStripeCheckout from '../../../../../../apis/stripeCheckout.js';
import pegarInfosUser from '../../../../../../functions/pegarInfosUser.js';


export default class PaginaUsuarioPainelLojaPagamentoSucesso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    try {
      let sessionId = this.props.location.query.sessionId;
      let sessaoCheckout = await ApisStripeCheckout.obter({
        query: { sessionId }
      });
      sessaoCheckout = sessaoCheckout.resposta;
      if (sessaoCheckout.id) {
        await this.setState({
          carregando: false,
          sessaoCheckout
        });
      }else{
        window.location.href = "/404";
      }
    } catch (error) {
      console.error(error);
      await this.setState({
        carregando: false,
        erro: String(error)
      });
    }
  }

  render() {
    return html(this);
  }
}