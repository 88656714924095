import { Component } from 'react';
import html from './paginas.jsx';
import pegarInfosUser from '../../../../functions/pegarInfosUser.js';

export default class PaginaUsuarioPainelPaginas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erro: null,
      carregando: true,
      ocultarWhats: true
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await pegarInfosUser(this);
    await this.setState({
      carregando: false,
      pMsg: this.props.location.query.msg
    });
  }

  render() {
    return html(this);
  }
}