import React from 'react';
import LayoutError from '../../layout/LayoutError';
import LayoutCarregando from '../../layout/LayoutCarregando';
import config from '../../../config';
import * as Funcoes from '../../../funcoes';
import './quadrados.css';
import './cores.css';
import ComponenteCardApp from '../../componentes/cardApp/cardApp';
import getYouTubeVideoId from '../../functions/getYouTubeVideoId';

export default function html(shelf) {

  if (shelf.state.erro !== null) {
    return (<LayoutError erro={shelf.state.erro}></LayoutError>);
  } else if (shelf.state.carregando === true) {
    return (<LayoutCarregando></LayoutCarregando>);
  } else {

    return (
      <div className={"divTopoTema tema-" + shelf.state.dadosPagina.modelo + ` background-${shelf.state.dadosPagina.cor}`} >
        <div className="container text-center">
          <div className="conteudo">
            {
              shelf.state.dadosPagina?.paginaAppsConfig?.["1_linkdaimagemdobanner00"] ? (<>
                <div className="banner-perfil">
                  <img src={shelf.state.dadosPagina?.paginaAppsConfig?.["1_linkdaimagemdobanner00"]} alt={"Banner do(a) " + shelf.state.dadosPagina.nome}></img>
                </div>
                <div className="imagem-perfil mt-negativo-70">
                  <img src={config.arquivos.baseUrl + shelf.state.dadosPagina.foto} alt={"Foto do(a) " + shelf.state.dadosPagina.nome}></img>
                </div>
              </>) : (<>
                <div className="imagem-perfil">
                  <img src={config.arquivos.baseUrl + shelf.state.dadosPagina.foto} alt={"Foto do(a) " + shelf.state.dadosPagina.nome}></img>
                </div>
              </>)
            }
            <br />
            <h2 className="nome-perfil">{shelf.state.dadosPagina.nome}</h2>
            <p>{shelf.state.dadosPagina.sobre}</p>

            {
              shelf.state.dadosPagina?.paginaAppsConfig?.["2_linkdovideodoyoutube00"] && (<>
                <div className="ratio ratio-16x9">
                  <iframe src={`https://www.youtube.com/embed/${getYouTubeVideoId(shelf.state.dadosPagina?.paginaAppsConfig?.["2_linkdovideodoyoutube00"])}`} title="YouTube video" allowfullscreen></iframe>
                </div>
                <br />
              </>)
            }
            {
              shelf.state.dadosPagina?.paginaAppsConfig?.["10_url00"] && (<>
                <div className="ratio ratio-16x9">
                  <iframe src={shelf.state.dadosPagina?.paginaAppsConfig?.["10_url00"]} allowfullscreen></iframe>
                </div>
                <br />
              </>)
            }

            {
              shelf.state.dadosPagina?.paginaAppsConfig?.["5_valordachave10"] && (<>
                <div className={`appPix pix-cor-${shelf.state.dadosPagina.cor} row`}>
                  <div className="input-group mb-3">
                    {/* eslint-disable-next-line */}
                    <span className="input-group-text input-esquerdo"><img src={"https://i.imgur.com/xrs1uye.png?1"} height="25px" className="me-2" /> {shelf.state.dadosPagina?.paginaAppsConfig?.["5_tipodachave00"]}</span>
                    <input type="text" className="form-control" defaultValue={shelf.state.dadosPagina?.paginaAppsConfig?.["5_valordachave10"]} />
                    <span className="input-group-text input-direito" onClick={() => Funcoes.appPixCopiar(shelf)}><i className="far fa-clone"></i></span>
                  </div>
                </div>
              </>)
            }

            <div className="links">
              {
                shelf.state.dadosPagina.link_whatsapp !== "WHATS_OCULTO" && (<>
                  {/* eslint-disable-next-line */}
                  <a className={`btn-${shelf.state.dadosPagina.cor} btn-link`} href={shelf.state.dadosPagina.link_whatsapp} title={"WhatsApp"}>
                    <i className="fab fa-whatsapp fa-1x link-icon"></i>
                    <p className="link-titulo">WhatsApp</p>
                  </a>
                </>)
              }
              {
                shelf.state?.dadosPagina?.links?.[0]?.titulo &&
                shelf.state.dadosPagina.links.map(link => (<>
                  {/* eslint-disable-next-line */}
                  <a className={`btn-${shelf.state.dadosPagina.cor} btn-link`} href={link.link} target="_blank" title={link?.titulo}>
                    <i className={link.icone + " fa-1x link-icon"} ></i>
                    <p className="link-titulo">{link?.titulo}</p>
                  </a>
                </>
                ))
              }
            </div>

          </div>
        </div>


        <footer className="rodape text-center">
          {
            (shelf.state.dadosPagina?.paginaAppsConfig?.["9_removerlogo00"] === "Sim") ? (<>
              <div className="mb-5"></div>
            </>) : (<>
              {/* eslint-disable-next-line */}
              <a href="https://icartao.me/" target="_blank">
                <img className="logo logo-app" src={process.env.PUBLIC_URL + "/img/Logo/Icartão_Logo_tamanho_normal.png"} alt="Logo" style={{ width: "100px" }} />
                <br /><br />
              </a>

              {
                (shelf?.props?.usuarioLogadoDonoPagina) ? (<>
                  <button className="btn btn-danger text-start" onClick={() => { ComponenteCardApp.onClickComprarApp(shelf, { id: 9 }) }}>
                    <i class="fad fa-eye-slash"></i> Remover Logo
                  </button>
                </>) : (<>
                  {/* eslint-disable-next-line */}
                  <a href="https://icartao.me/" className="btn btn-painel-roxo" target="_blank"><i class="fad fa-user-plus"></i> Crie seu iCartão grátis</a>
                </>)
              }
            </>)
          }
        </footer>

      </div>
    );
  }
}
